import React, { memo } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import clsx from "classnames";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import CheckBox from "components/General/Input/CheckBox";
import { ReactComponent as Bank } from "assets/icons/Transactions/bank.svg";
import { ReactComponent as Mobile } from "assets/icons/Transactions/mobile.svg";
import { ReactComponent as Crypto } from "assets/icons/Transactions/crypto.svg";
import {
  NAIRA_ABBR,
  walletConstants,
  AMAZON_ASSET_URL,
} from "utils/appConstant";
import useWallet from "hooks/wallet";
import SelectTile from "components/General/SelectTile";

const { BANK_TRANSFER, MOBILE_MONEY, CRYPTO } = walletConstants;

export const SelectWithdrawalMethods = memo(function SelectWithdrawalMethods({
  next,
  selectedWallet,
  selectedMethod,
}) {
  const IS_CRYPTO = !!selectedWallet?.crypto_wallet_type;
  const withdrawalMethods = [
    {
      name: "Bank Transfer",
      description: "Transfer your funds to your bank account",
      icon: <Bank />,
      type: BANK_TRANSFER,
      show: !IS_CRYPTO,
    },
    {
      name: "Mobile money",
      description: "Withdraw your funds through mobile money",
      icon: <Mobile />,
      type: MOBILE_MONEY,
      // show: !IS_CRYPTO,
      show: false,
    },
    {
      name: "Cryptocurrency",
      description: "Withdraw your crypto funds",
      icon: <Crypto />,
      type: CRYPTO,
      show: IS_CRYPTO,
    },
  ].filter((item) => item.show);
  return (
    <div className="flex flex-col gap-4 justify-start items-start w-full bg-grey-light3 py-4 md:py-6 px-3 md:px-4 my-6 rounded-2xl">
      {withdrawalMethods.map((method, index) => (
        <SelectTile
          key={index}
          onClick={() => next(method.type)}
          method={method}
          checked={method.type === selectedMethod}
        />
      ))}
    </div>
  );
});

export const Confirmation = function Confirmation({
  transferDetails,
  setTransferDetails,
  CRYPTO,
  receiver_address,
  minerFee,
  transactionType,
  conversionRate,
  isLoading,
  error,
  errorMessage,
}) {
  const {
    account_details: { account_name },
    fetchBeneficiariesError,
  } = useWallet();

  const {
    to,
    amount,
    account_number,
    to_amount,
    from,
    bank_name,
    intl_transfer_account_name,
    beneficiary_account_name,
    beneficiary_account_number,
    beneficiary_bank_name,
    payout_details,
  } = transferDetails;

  const IS_MOBILE_MONEY = transactionType === MOBILE_MONEY;

  const toggleSaveToBeneficiary = () => {
    setTransferDetails({
      ...transferDetails,
      save_as_beneficiary: !transferDetails.save_as_beneficiary,
    });
  };

  return (
    <div
      className={clsx("w-full overflow-hidden my-6", {
        "my-6": !transferDetails?.pageType,
        "my-2": transferDetails?.pageType === "transaction_details",
      })}
    >
      {transactionType === CRYPTO ? (
        <div className="px-[0.8rem] py-[1.25rem] w-full">
          {isLoading ? (
            <div className="flex flex-col justify-center items-center h-full w-full ">
              <CircleLoader blue />
              <p className="text-[12px] text-center text-black">
                Currently checking your payment. This will take some minutes,
                please be patient
              </p>
            </div>
          ) : (
            <>
              {error ? (
                <div className="flex flex-col justify-center items-center h-full w-full">
                  <img
                    src={AMAZON_ASSET_URL + "fail.gif"}
                    alt="i"
                    style={{
                      marginBottom: "0px",
                      width: "150px",
                      height: "150px",
                    }}
                  />
                  <p className="mb-1 text-[15px] text-center font-bold w-full">
                    Error encountered!
                  </p>
                  <p className="mb-1 text-[12px] text-center font-bold w-full">
                    {errorMessage}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col justify-start items-start max-h-[290px] px-[0.8rem] py-[1.25rem] border border-grey-border bg-[#F5F6FA] rounded-[7.5px]">
                  <div className="flex flex-row justify-between items-start w-full mb-5">
                    <div className="flex flex-col justify-start items-start">
                      <p className="mb-1 text-[11px] text-gray-400">
                        Receiver’s address
                      </p>
                      <p className="mb-1 text-sm font-bold">
                        {receiver_address}
                      </p>
                    </div>
                    <div className="flex flex-col justify-start items-start"></div>
                  </div>
                  <div className="flex flex-row justify-between items-start w-full mb-5">
                    <div className="flex flex-col justify-start items-start">
                      <div className="flex flex-col justify-start items-start">
                        <p className="mb-1 text-[11px] text-gray-400">
                          Amount to send
                        </p>
                        <p className="mb-1 text-sm font-bold">
                          {`${conversionRate?.to_coin_amount || amount} ${
                            conversionRate?.to_coin_type?.toUpperCase() ||
                            from?.toUpperCase()
                          }`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-end">
                      <p className="mb-1 text-[11px] text-gray-400">
                        Network Fee
                      </p>
                      <p className="mb-1 text-sm font-bold">
                        {minerFee?.coin_send_fee} {from?.toUpperCase()}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="py-8 px-6 flex flex-col justify-center items-center w-full">
          <p className="text-center text-lg text-grey-text">
            You are about to send{" "}
            <span className="text-black">
              {to}{" "}
              <NumberFormat
                value={
                  IS_MOBILE_MONEY
                    ? to_amount
                    : amount || payout_details?.receiver_amount
                }
                displayType="text"
                thousandSeparator
              />
            </span>{" "}
            to
            <span className="text-black">
              {" "}
              {transactionType === BANK_TRANSFER
                ? `${
                    from === NAIRA_ABBR
                      ? `${
                          beneficiary_account_name ||
                          account_name ||
                          payout_details?.receiver_account_name
                        } ${
                          beneficiary_account_number ||
                          account_number ||
                          payout_details?.receiver_account_num
                        } ${
                          beneficiary_bank_name ||
                          bank_name ||
                          payout_details?.receiver_bank_name
                        }`
                      : `${intl_transfer_account_name} ${account_number} ${bank_name}`
                  }`
                : transactionType === MOBILE_MONEY
                ? account_number || beneficiary_account_number
                : " "}
            </span>
            . Kindly click confirm to continue transaction.
          </p>

          {!fetchBeneficiariesError && !transferDetails.beneficiary_payout_ref && (
            <button
              className="border-none flex space-x-[11px] items-center mt-[25px]"
              onClick={toggleSaveToBeneficiary}
            >
              <CheckBox square checked={transferDetails.save_as_beneficiary} />
              <span className="font-[550] text-sm mb-[-3px] text-blue">
                Save to beneficiary list
              </span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

SelectWithdrawalMethods.propTypes = {
  next: PropTypes.func,
  selectedWallet: PropTypes.object,
  selectedMethod: PropTypes.string,
};

Confirmation.propTypes = {
  transferDetails: PropTypes.object,
  conversionRate: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  transactionType: PropTypes.string,
  minerFee: PropTypes.object,
  crypto: PropTypes.object,
  receiver_address: PropTypes.string,
  CRYPTO: PropTypes.string,
  errorMessage: PropTypes.string,
  setTransferDetails: PropTypes.func,
};
