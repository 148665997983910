import React from "react";
import PropTypes from "prop-types";

const CheckBox = ({ checked, square }) => {
  const outer = {
    width: "20px",
    height: "20px",
    borderRadius: square ? "3px" : "10px",
    border: "1.5px solid #CECBF7",
    flexShrink: 0,
  };

  const inner = {
    backgroundColor: "transparent",
    height: "12px",
    width: "12px",
    borderRadius: square ? "3px" : "6px",
  };

  const styles = {
    checkedOuter: {
      ...outer,
      border: "1.5px solid #5444F2",
    },
    checkedInner: {
      ...inner,
      backgroundColor: "#5444F2",
    },
  };
  return (
    <div
      className="flex justify-center items-center"
      style={checked ? styles.checkedOuter : outer}
    >
      <div style={checked ? styles.checkedInner : inner}></div>
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  square: PropTypes.bool,
};

export default CheckBox;
