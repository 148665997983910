import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  error: false,
  transactions: [],
  reversedTransactions: null,
  loadingReversedTransactions: false,
  transactionDetailsLoading: false,
  transactionDetails: null,
  searchResults: null,
  searchResultsCount: 0,
  searching: false,
  searchError: false,
  exporting: false,
  exportResults: "",
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getTransactionsList: (state) => {
      state.loading = true;
    },
    getTransactionsListSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
    },

    getTransactionsListError: (state, action) => {
      state.loading = false;
      state.transactions = null;
    },
    getReversedTransactionsList: (state) => {
      state.loadingReversedTransactions = true;
    },
    getReversedTransactionsListSuccess: (state, action) => {
      state.loadingReversedTransactions = false;
      state.reversedTransactions = action.payload;
    },

    getReversedTransactionsListError: (state) => {
      state.loadingReversedTransactions = false;
    },
    resetReversedTransactionsList: (state) => {
      state.loadingReversedTransactions = true;
    },
    resetTransactionsList: (state) => {
      state.loading = true;
    },
    getTransactionDetails: (state) => {
      state.transactionDetailsLoading = true;
      state.transactionDetails = null;
    },
    getTransactionDetailsSuccess: (state, action) => {
      state.transactionDetailsLoading = false;
      state.transactionDetails = action.payload;
    },
    getTransactionDetailsError: (state, action) => {
      state.transactionDetailsLoading = false;
      state.transactionDetails = null;
    },
    searchTransactions: (state) => {
      state.searching = true;
    },
    searchTransactionsError: (state) => {
      state.searching = false;
      state.searchError = true;
      state.searchResults = null;
    },

    searchTransactionsSuccess: (state, action) => {
      state.searching = false;
      state.searchError = false;
      state.searchResults = action.payload?.data;
      state.searchResultsCount = action.payload?.count;
    },

    exportAccountTransactions: (state) => {
      state.exporting = true;
      state.exportResults = "";
    },
    exportAccountTransactionsSuccess: (state, action) => {
      state.exporting = false;
      state.exportResults = action.payload;
    },
    exportAccountTransactionsError: (state, action) => {
      state.exporting = false;
      state.exportResults = "";
    },
    resetExportAccountTransactions: (state) => {
      state.exporting = false;
      state.exportResults = "";
    },
    resetSearchState: (state) => {
      state.searching = false;
      state.searchError = false;
      state.searchResults = null;
    },
    resetTransactionDetails: (state) => {
      state.transactionDetails = null;
    },
  },
});

export default transactionsSlice;
