import { apiInstance2 } from "utils/apiInstance";

const getHeaders = (tribeRef) => ({
  "MERCHANT-TRIBE-REF": tribeRef,
});

const apis = {
  createFiatWallet: (payload) =>
    apiInstance2("/account/add_fiat_wallet/", {
      data: payload,
      method: "POST",
    }),

  createCryptoWallet: (payload) =>
    apiInstance2("/account/add_crypto_wallet/", {
      data: payload,
      method: "POST",
    }),

  fetchFiatWallets: (tribeAccountID) =>
    apiInstance2(`/account/fiat_wallets/${tribeAccountID}/`),

  fetchCryptoWallets: (tribeAccountID) =>
    apiInstance2(`/account/crypto_wallets/${tribeAccountID}/`),

  fetchTransactions: ({ page, payload, tribeRef }) =>
    apiInstance2(`/partner/transactions/?page=${page}`, {
      method: "POST",
      data: payload,
      headers: {
        "merchant-tribe-ref": tribeRef,
      },
    }),

  fetchSupportedCryptoWallets: () =>
    apiInstance2("/partner/list_supported_crypto/"),

  getFiatToFiatConversion: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/collection/fiat/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),
  getFiatToCoinConversion: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/collection/convert_coin_fiat/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),

  getMinerFee: ({ tribeRef, ...payload }) =>
    apiInstance2("/trybe/check_coin_send_fee/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),

  cryptoPayout: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/payout/send_a_coin/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),
  checkCryptoPayoutStatus: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/payout/crypto_status_check/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),

  getCryptoFiatCurrencyConversion: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/collection/convert_coin_to_coin/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),

  swapCurrencies: ({ tribeRef, ...payload }) =>
    apiInstance2("/trybe/exchange_express/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
      hideToast: true,
    }),

  refundCustomer: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/refund/shopper/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),

  resendWebhook: ({ tribeRef, ...payload }) =>
    apiInstance2("/trapmen/resend_check_webhook/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),

  fetchBanks: (countryCode) =>
    apiInstance2(`/partner/list_banks/${countryCode}/`),

  verifyBankAccount: (payload) =>
    apiInstance2("/partner/payout/verify_bank_account/", {
      method: "POST",
      data: payload,
    }),

  bankTransfer: ({ tribeRef, ...payload }) =>
    apiInstance2("/partner/payout/initiate_transfer/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),
  lookupMerchantAccountNumber: ({ tribeRef, payload }) =>
    apiInstance2("/partner/collection/customer_perm_account_number/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),
  payWithBankTransfer: ({ tribeRef, payload }) =>
    apiInstance2("/partner/collection/bank_transfer/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),
  listAllBeneficiaries: ({ tribeRef }) =>
    apiInstance2("/partner/payout/beneficiaries/", {
      headers: {
        ...getHeaders(tribeRef),
      },
    }),
  transferToBeneficiary: ({ tribeRef, payload }) =>
    apiInstance2("/partner/payout/initiate_beneficiary_transfer/", {
      method: "POST",
      data: payload,
      headers: { ...getHeaders(tribeRef) },
    }),
  exportWalletTransactions: (data) =>
    apiInstance2(`/trybe/csv_per_account_export/`, {
      method: "POST",
      data,
    }),

  confirmPassword: (payload) =>
    apiInstance2("/account/validate_account_password/", {
      data: payload,
      method: "POST",
    }),
};

export default apis;
