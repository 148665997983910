import { ReactComponent as Crypto } from "assets/icons/Transactions/crypto.svg";
import { ReactComponent as BankIcon } from "assets/icons/Transactions/bank.svg";
import { ReactComponent as FiatToFiat } from "assets/icons/Transactions/fiat-to-fiat.svg";

export const STAGE_ONE = 1;
export const STAGE_TWO = 2;
export const STAGE_THREE = 3;
export const STAGE_FOUR = 4;
export const STAGE_FIVE = 5;

export const FIRST_STEP = "first_step";
export const SECOND_STEP = "second_step";
export const THIRD_STEP = "third_step";

export const MERCHANT = "merchant";

export const STATUS_CODE_SUCCESS = 200;
export const STATUS_CODE_APPROVED = 201;
export const STATUS_CODE_FAILED = 500;

export const walletConstants = {
  BANK_TRANSFER: "BANK_TRANSFER",
  MOBILE_MONEY: "MOBILE_MONEY",
  BENEFICIARY_SELECTION: "BENEFICIARY_SELECTION",
  CRYPTO: "CRYPTO",
  FIAT: "FIAT",
  EWALLET: "EWALLET",
  INDEX: "INDEX",
  PRE_TRANSFER_DETAILS_FORM: "PRE_TRANSFER_DETAILS_FORM",
  TRANSFER_DETAILS_FORM: "TRANSFER_DETAILS_FORM",
  CONFIRMATION: "CONFIRMATION",
  TRANSFER_TYPES: {
    LOCAL: "LOCAL",
    INTERNATIONAL: "INTERNATIONAL",
    CRYPTO: "CRYPTO",
  },
};

export const SWAP_OPTIONS = {
  COIN_TO_FIAT: "coin2fiat",
  COIN_TO_COIN: "coin2coin",
  FIAT_TO_COIN: "fiat2coin",
  FIAT_TO_FIAT: "fiat2fiat",
};

export const pageCount = 20;
export const EMPTY_KEYS_MESSAGE = "No keys found for this account";

export const AUTHENTICATOR = "AUTHENTICATOR";
export const EMAIL = "EMAIL";

export const NOTIFICATION_TYPES = {
  login: "notify_sign_in",
  customer_transaction_notification: "notify_customer",
  none: "notify_pause",
  notify_credit: "notify_credit",
  notify_debit: "notify_debit",
};

export const PRODUCT_CATEGORY_SAMPLE = ["food", "fish", "fashion"];

export const ORDER_STATUS = ["active", "expired"];

export const PAYMENT_STATUS = ["unpaid", "paid", "part_paid"];

export const PAYMENT_METHOD = [
  "bank_transfer",
  "mobile_money",
  "crypto",
  "ewallet",
  "bank_direct_debit",
];

export const MERCHANT_KEYS = {
  PUBLIC_KEY: "PUBLIC_KEY",
  PRIVATE_KEY: "PRIVATE_KEY",
  ACCOUNT_TRIBE_REF: "ACCOUNT_TRIBE_REF",
  ACCESS_TOKEN: "ACCESS_TOKEN",
};

export const IS_DEV = [
  "testflight.getbani.com",
  "localhost",
  "playground.bani.africa",
].includes(window.location.hostname);

export const NAIRA_ABBR = "NGN";
export const NIGERIA_ABBR = "NG";

export const teamMemberDefaultActionsState = (isAccount = false) => {
  return {
    can_add: false,
    can_authorize: false,
    can_read: isAccount,
    can_delete: false,
    can_edit: false,
  };
};

export const TEAM_MEMBER_FORM_STEPS = {
  ENTRY_FORM: "ENTRY_FORM",
  SET_PERMISSIONS: "SET_PERMISSIONS",
};
export const TEAM_MEMBER_UNIT_NAMES = {
  ACCOUNT: "account",
  PAYMENT: "payment",
  COMMERCE_HUB: "commerce_hub",
  TEAM: "team",
  API: "api",
};
export const TEAM_MEMBER_UNITS = {
  account: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.ACCOUNT,
    ...teamMemberDefaultActionsState(true),
  },
  payment: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.PAYMENT,
    ...teamMemberDefaultActionsState(),
  },
  commerce_hub: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.COMMERCE_HUB,
    ...teamMemberDefaultActionsState(),
  },
  team: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.TEAM,
    ...teamMemberDefaultActionsState(),
  },
  api: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.API,
    ...teamMemberDefaultActionsState(),
  },
};

export const TEAM_MEMBER_UNITS_ALT = {
  account: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.ACCOUNT,
    ...teamMemberDefaultActionsState(),
  },
  payment: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.PAYMENT,
    ...teamMemberDefaultActionsState(),
  },
  commerce_hub: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.COMMERCE_HUB,
    ...teamMemberDefaultActionsState(),
  },
  team: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.TEAM,
    ...teamMemberDefaultActionsState(),
  },
  api: {
    unit_name: TEAM_MEMBER_UNIT_NAMES.API,
    ...teamMemberDefaultActionsState(),
  },
};

export const TEAM_MEMBER_ACTIONS = {
  can_add: "can_add",
  can_authorize: "can_authorize",
  can_read: "can_read",
  can_delete: "can_delete",
  can_edit: "can_edit",
};

export const accountManagmentSettings = [
  { name: "Can view account", code: TEAM_MEMBER_ACTIONS.can_read },
  { name: "Can add new wallets", code: TEAM_MEMBER_ACTIONS.can_add },
  {
    name: "Can edit existing wallet details",
    code: TEAM_MEMBER_ACTIONS.can_edit,
  },

  {
    name: "Can delete existing wallets",
    code: TEAM_MEMBER_ACTIONS.can_delete,
  },
  {
    name: "Can authorise swaps between wallets",
    code: TEAM_MEMBER_ACTIONS.can_authorize,
  },
];

export const teamSettings = [
  { name: "Can view all team members", code: TEAM_MEMBER_ACTIONS.can_read },
  { name: "Can add new team members", code: TEAM_MEMBER_ACTIONS.can_add },
  {
    name: "Can edit team member details",
    code: TEAM_MEMBER_ACTIONS.can_edit,
  },
  {
    name: "Can delete existing team members",
    code: TEAM_MEMBER_ACTIONS.can_delete,
  },
];

export const paymentSettings = [
  { name: "Can authorise payouts", code: TEAM_MEMBER_ACTIONS.can_authorize },
  {
    name: "Can view wallets and transactions",
    code: TEAM_MEMBER_ACTIONS.can_read,
  },
  { name: "Can add beneficiaries", code: TEAM_MEMBER_ACTIONS.can_add },
  { name: "Can delete beneficiaries", code: TEAM_MEMBER_ACTIONS.can_delete },
];

export const cartSettings = [
  {
    name: "Can view all products, customers and orders",
    code: TEAM_MEMBER_ACTIONS.can_read,
  },
  {
    name: "Can add new products, customers, and orders",
    code: TEAM_MEMBER_ACTIONS.can_add,
  },
  {
    name: "Can edit product, customer and order details",
    code: TEAM_MEMBER_ACTIONS.can_edit,
  },
  {
    name: "Can delete an existing product, customer or order",
    code: TEAM_MEMBER_ACTIONS.can_delete,
  },
];

export const apiAndWebhookSettings = [
  { name: "Can view API keys", code: TEAM_MEMBER_ACTIONS.can_read },
  {
    name: "Can regenerate API keys and update the webhook URL",
    code: TEAM_MEMBER_ACTIONS.can_edit,
  },
];

export const pendingTransactionStatus = [
  "in_progress",
  "source_processing",
  "pending",
  "on_going",
];
export const sucessfulTransactionStatus = ["completed", "paid"];
export const failedTransactionStatus = ["failed"];
export const partPaidTransactionStatus = ["on_going", "part_payment"];
export const isRequired = { required: true };
export const REQUEST_TYPES = {
  POST: "POST",
};

export const BRANCH_ACTIONS = {
  SELECT: "SELECT",
  CREATE: "CREATE",
};

export const PAYMENT_LINK_FORM_VIEWS = {
  ENTRY_FORM: "ENTRY_FORM",
  CREATION_FORM: "CREATION_FORM",
  CREATION_SUCCESS: "CREATION_SUCCESS",
  SHARE_FORM: "SHARE_FORM",
  EDIT_FORM: "EDIT_FORM",
};

export const PAYMENT_LINK_ACTIVITY_VIEWS = {
  ACTIVITY_FEED: "ACTIVITY_FEED",
  ACTION_OPTIONS: "ACTION_OPTIONS",
  DELETE_MODAL: "DELETE_MODAL",
  EDIT_FORM: "EDIT_FORM",
};

export const VIRTUAL_ACCOUNT_ACTIVITY_VIEWS = {
  ACTIVITY_FEED: "ACTIVITY_FEED",
  ACTION_OPTIONS: "ACTION_OPTIONS",
  DELETE_MODAL: "DELETE_MODAL",
  MOVE_VIRTUAL_ACCOUNT: "MOVE_VIRTUAL_ACCOUNT",
};
export const VIRTUAL_ACCOUNT_EMPTY_BRANCH_VIEWS = {
  ACTION_OPTIONS: "ACTION_OPTIONS",
  DELETE_MODAL: "DELETE_MODAL",
};

export const AMAZON_ASSET_URL =
  "https://bani-assets.s3.eu-west-2.amazonaws.com/static/widget/images/";

export const CLOUDINARY_URL =
  "https://res.cloudinary.com/olamilekan1/image/upload/v1643910214/";

export const transactions = [];
const { COIN_TO_FIAT, FIAT_TO_COIN, FIAT_TO_FIAT } = SWAP_OPTIONS;
export const swapMethods = [
  {
    name: "Coin to fiat",
    description: "Exchange your crypto to currency (fiat) ",
    icon: <BankIcon />,
    type: COIN_TO_FIAT,
  },
  {
    name: "Fiat to coin",
    description: "Exchange your currency (fiat) to crypto",
    icon: <Crypto />,
    type: FIAT_TO_COIN,
  },
  {
    name: "Fiat to fiat",
    description: "Exchange your currency to another currency",
    icon: <FiatToFiat />,
    type: FIAT_TO_FIAT,
  },
];
export const settlement_settings_options = {
  webhook_url: "webhook_url",
  merchant_shared_key: "merchant_shared_key",
  crypto_settle_status: "crypto_settle_status",
  partner_dlt_settle_type: "partner_dlt_settle_type",
  collect_settle_type: "collect_settle_type",
};

export const crypto_settle_status_options = [
  { label: "Auto Settlement", value: "auto_settlement" },
  { label: "Manual Settlement", value: "manual_settlement" },
];

export const partner_dlt_settle_type_options = [
  { label: "Fiat", value: "fiat" },
  { label: "DLT", value: "crypto" },
];

export const collect_settle_type_options = [
  { label: "Base Currency", value: "base_currency" },
  { label: "Charge Currency", value: "direct_currency" },
];

export const getBankLogo = (bank_name) => {
  const bankLogos = [
    {
      bank_name: "9 payment service bank",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2023/03/09/9ps-logo_Fr2IQXc.png",
      widget_enabled: false,
    },
    {
      bank_name: "first city monument bank",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2022/11/08/fcmb_logo.png",
      widget_enabled: false,
    },
    {
      bank_name: "guaranty trust bank",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2022/11/08/gtbank_logo.png",
      widget_enabled: true,
    },
    {
      bank_name: "heritage",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2022/11/08/heritage_logo.png",
      widget_enabled: false,
    },
    {
      bank_name: "parkway-readycash",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2023/01/13/logo.png",
      widget_enabled: true,
    },
    {
      bank_name: "providus",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2022/09/27/providus_bank.png",
      widget_enabled: true,
    },
    {
      bank_name: "stanbic ibtc",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2022/11/08/stanbic_ibtc_logo.png",
      widget_enabled: false,
    },
    {
      bank_name: "wema",
      currency: "NGN",
      bank_logo:
        "https://stage-banizen.s3.amazonaws.com/media/pm/avatars/2022/09/27/wema_bank.png",
      widget_enabled: true,
    },
  ];
  const logo = bankLogos?.find(
    (_) =>
      _.bank_logo.toLowerCase()?.includes(bank_name?.toLowerCase()) ||
      bank_name?.toLowerCase()?.includes(_.bank_name.toLowerCase())
  );
  return logo?.bank_logo || "";
};

export const MERCHANTS_MODAL_TYPES = {
  SEARCH_FILTER: "SEARCH_FILTER",
  MECHANT_TRANSACTIONS_FILTER: "MECHANT_TRANSACTIONS_FILTER",
};

export const STAT_DATE_TYPE = {
  MONTHLY: "monthly",
  WEEKLY: "weekly",
};
