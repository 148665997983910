import React from "react";
import PropTypes from "prop-types";

const ModalFooter = ({ children }) => {
  return (
    <div className="p-[20px] text-xl text-left modal-footer">{children}</div>
  );
};

ModalFooter.propTypes = {
  children: PropTypes.elementType,
};

export default ModalFooter;
