import { apiInstance2 } from "../utils/apiInstance";
import { getHeaders } from "utils/request";

const apis = {
  listAndFilterTransactions: ({ payload, tribeRef, page }) =>
    apiInstance2(`/trapmen/list_general_transactions/?page=${page}`, {
      method: "POST",
      body: payload,
      headers: {
        ...getHeaders({ tribeRef }),
      },
    }),
  getTransactionDetails: ({ transaction_reference, tribeRef }) =>
    apiInstance2(
      `/trapmen/single_general_transaction/${transaction_reference}/`,
      {
        headers: {
          ...getHeaders({ tribeRef }),
        },
      }
    ),
  searchTransactions: ({ data }) =>
    apiInstance2(`/trapmen/direct_general_tx_search/`, {
      method: "POST",
      data,
    }),

  exportAccountTransactions: (data) =>
    apiInstance2(`/trybe/csv_per_account_export/`, {
      method: "POST",
      data,
    }),
};

export default apis;
