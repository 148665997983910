/* eslint-disable react/prop-types */
import DataTable, { createTheme } from "react-data-table-component";
import PropTypes from "prop-types";
import clsx from "classnames";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import { areArraysEqual } from "utils/functions";
import { ReactComponent as ChevronDown } from "assets/icons/Arrow/chevron-down.svg";
import CheckBox from "../Input/CheckBox";
import { TableWrapper } from "./table.style";
import Pagination from "./Pagination";

createTheme("default", {
  text: {
    primary: "#65717c",
    secondary: "#000000",
  },
  background: {
    default: "transparent",
  },
  divider: {
    default: "rgba(245, 246, 250, 1);",
  },
  action: {
    hover: "yellow",
  },
  striped: {
    default: "rgba(245, 246, 250, 0.7)",
  },
});

const renderMobileRows = (mobileRowRender, keyField, data, otherProps) => {
  const onRowPress = (row) => {
    if (otherProps?.onRowClicked) {
      otherProps.onRowClicked(row);
    }
  };
  return (
    <div>
      {data.map((row, index) => (
        <div
          onClick={() => onRowPress(row)}
          key={row[keyField]}
          className={clsx({ "cursor-pointer": otherProps?.onRowClicked })}
        >
          {mobileRowRender(row, index)}
        </div>
      ))}
    </div>
  );
};
const CustomSortIcon = ({ isSorted, isSortedDesc }) => {
  if (isSorted) {
    return isSortedDesc ? (
      <ChevronDown />
    ) : (
      <ChevronDown className="rotate-180" />
    );
  }
  return null;
};
export default function Table({
  columns,
  mobileColumns,
  data,
  extendMinHeight,
  isLoading,
  extraChild,
  pageCount,
  onPageChange,
  currentPage,
  tableClassName,
  isAlt,
  mobileRowRender,
  keyField,
  noPadding,
  hasCheckBox,
  onSelectAll,
  onSelectRow,
  selectedRows,
  headerComponent,
  ...rest
}) {
  const indexedData = data?.map((item, i) => {
    return { ...item, index: String(i) };
  });
  const indexedDataIndices = indexedData?.map((item) => item?.index) || [];
  const isAllSelected = areArraysEqual(indexedDataIndices, selectedRows);
  const handleSelectAll = () => {
    if (isAllSelected) {
      onSelectAll?.([]);
    } else {
      onSelectAll?.(indexedDataIndices);
    }
  };

  const isRowSelected = (i) => selectedRows?.find((item) => item === i);

  const handleSelectRow = (i) => {
    if (isRowSelected(i)) {
      onSelectRow?.(selectedRows?.filter((item) => item !== i));
    } else {
      onSelectRow?.([...selectedRows, i]);
    }
  };

  return (
    <TableWrapper extendMinHeight={extendMinHeight} noPadding={noPadding}>
      <div className={`${isAlt ? "" : "table-container"} ${tableClassName}`}>
        {headerComponent}
        <div className="hidden md:block">
          <DataTable
            columns={[
              hasCheckBox
                ? {
                    name: (
                      <span
                        className="cursor-pointer"
                        onClick={handleSelectAll}
                      >
                        <CheckBox square checked={isAllSelected} />
                      </span>
                    ),
                    selector: (row) => (
                      <span
                        className="cursor-pointer"
                        onClick={() => handleSelectRow?.(row?.index)}
                      >
                        <CheckBox
                          square
                          checked={!!isRowSelected(row?.index)}
                        />
                      </span>
                    ),
                    maxWidth: "80px",
                    minWidth: "80px",
                  }
                : null,
              ...columns,
            ]?.filter((item) => item)}
            data={indexedData}
            theme="default"
            progressPending={isLoading}
            progressComponent={<CircleLoader />}
            sortIcon={<CustomSortIcon />}
            {...rest}
          />
          {extraChild}
        </div>
        <div className="block md:hidden">
          {mobileRowRender ? (
            renderMobileRows(mobileRowRender, keyField, indexedData, rest)
          ) : (
            <div>
              <DataTable
                columns={mobileColumns || columns}
                data={indexedData}
                theme="default"
                progressPending={isLoading}
                progressComponent={
                  <div className="min-h-[300px] w-full flex items-center justify-center">
                    <CircleLoader blue />
                  </div>
                }
                {...rest}
              />
            </div>
          )}
          {extraChild}
        </div>
      </div>

      <Pagination {...{ pageCount, onPageChange, currentPage }} />
    </TableWrapper>
  );
}

Table.propTypes = {
  extendMinHeight: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  rest: PropTypes.object,
  extraChild: PropTypes.elementType,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  isAlt: PropTypes.bool,
  tableClassName: PropTypes.string,
  mobileRowRender: PropTypes.func,
  keyField: PropTypes.string,
  noPadding: PropTypes.bool,
  hasCheckBox: PropTypes.bool,
  headerComponent: PropTypes.elementType,
};
