import { call, put, takeLatest } from "redux-saga/effects";
import { errorToast, successToast } from "components/General/Toast/Toast";
import apis from "services/wallet";
import { SWAP_OPTIONS, walletConstants } from "utils/appConstant";
import walletSlice from ".";

const { actions } = walletSlice;
const { COIN_TO_COIN, FIAT_TO_FIAT, FIAT_TO_COIN, COIN_TO_FIAT } = SWAP_OPTIONS;
const { CRYPTO, FIAT } = walletConstants;

function* createWallet(action) {
  const onError = action.payload?.onError;
  const onSuccess = action.payload?.onSuccess;
  try {
    const payload = action.payload?.payload;
    const createWalletAPI =
      payload.type === CRYPTO ? apis.createCryptoWallet : apis.createFiatWallet;
    yield call(createWalletAPI, payload);
    successToast("Success", "Wallet created successfully");

    yield put(
      actions.fetchWallets({
        tribe_account_id: payload.tribe_account_id,
        newWallet: payload.wallet_currency,
        newWalletType: payload.type,
      })
    );
    onSuccess?.();
  } catch (e) {
    yield put(actions.createWalletError());
    onError?.();
  }
}

function* fetchWallets(action) {
  try {
    const fiatWallets = yield call(
      apis.fetchFiatWallets,
      action.payload.tribe_account_id
    );
    const cryptoWallets = yield call(
      apis.fetchCryptoWallets,
      action.payload.tribe_account_id
    );

    yield put(
      actions.fetchWalletsSuccess({
        fiat: fiatWallets.data,
        crypto: cryptoWallets.data,
        refresh_selected_wallet: action.payload.refresh_selected_wallet,
        fiat_wallet_id: action.payload.fiat_wallet_id,
        coin_wallet_id: action.payload.coin_wallet_id,
      })
    );

    if (action.payload.newWallet) {
      if (action.payload.newWalletType === CRYPTO) {
        const newWallet = {
          type: CRYPTO,
          id: cryptoWallets.data.find(
            (_) => _.crypto_wallet_type === action.payload.newWallet
          )?.crypto_wallet_id,
        };
        yield put(actions.setSelectedWalletDetails(newWallet));
        yield put(actions.createWalletSuccess(newWallet));
      } else {
        const newWallet = {
          type: FIAT,
          id: fiatWallets.data.find(
            (_) => _.currency === action.payload.newWallet
          )?.fiat_wallet_id,
        };
        yield put(actions.setSelectedWalletDetails(newWallet));
        yield put(actions.createWalletSuccess(newWallet));
      }
    }
  } catch (e) {
    yield put(actions.fetchWalletsError());
  }
}

function* fetchTransactions(action) {
  const { payload } = action;
  try {
    const response = yield call(apis.fetchTransactions, payload);
    yield put(actions.fetchTransactionsSuccess(response));
  } catch (e) {
    errorToast("Error", "Failed to fetch transactions for this wallet");
    yield put(actions.fetchTransactionsError());
  }
}

function* fetchSupportedCryptoWallets(action) {
  try {
    const { data } = yield call(apis.fetchSupportedCryptoWallets);
    yield put(actions.fetchSupportedCryptoWalletsSuccess(data));
  } catch (e) {
    yield put(actions.fetchSupportedCryptoWalletsError());
  }
}

function* getMinerFee(action) {
  try {
    const response = yield call(apis.getMinerFee, action.payload);
    yield put(actions.getMinerFeeSuccess(response));
  } catch (e) {
    yield put(actions.getMinerFeeError(e));
  }
}

function* cryptoPayout(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  const onError = action?.payload?.onError;
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(apis.cryptoPayout, payload);

    yield put(
      actions.checkCryptoPayoutStatus({
        tribeRef: payload?.tribeRef,
        payout_ref: response?.payout_ref,
      })
    );
    onSuccess?.();
  } catch (e) {
    yield put(actions.cryptoPayoutError(e));
    onError?.({
      errorMessage: e?.response?.data?.message,
    });
  }
}

function* checkCryptoPayoutStatus(action) {
  try {
    const response = yield call(apis.checkCryptoPayoutStatus, action?.payload);

    if (response?.data?.transfer_status === "completed") {
      yield put(actions.checkCryptoPayoutStatusSuccess(response));
    } else {
      yield put(
        actions.checkCryptoPayoutStatus({
          tribeRef: action?.payload?.tribeRef,
          payout_ref: action?.payload?.payout_ref,
        })
      );
    }
  } catch (e) {
    yield put(actions.checkCryptoPayoutStatusError(e));
  }
}

function* fetchConversionRate(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  const onError = action?.payload?.onError;
  try {
    const { conversion_type } = payload;

    const api =
      conversion_type === COIN_TO_COIN
        ? apis.getCryptoFiatCurrencyConversion
        : conversion_type === FIAT_TO_FIAT
        ? apis.getFiatToFiatConversion
        : conversion_type === FIAT_TO_COIN || conversion_type === COIN_TO_FIAT
        ? apis.getFiatToCoinConversion
        : "";

    const data = yield call(api, payload);
    yield put(actions.getConversionRateSuccess(data));
    onSuccess?.();
  } catch (e) {
    yield put(actions.getConversionRateError());
    onError?.({
      errorMessage: e?.response?.data?.message,
    });
  }
}

function* swapCurrencies(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  const onError = action?.payload?.onError;
  try {
    const data = yield call(apis.swapCurrencies, payload);
    yield put(actions.swapSuccess(data));
    yield put(
      actions.fetchWallets({
        tribe_account_id: payload.tribe_account_id,
      })
    );
    onSuccess?.();
  } catch (e) {
    yield put(actions.swapError(e?.response?.data?.message));
    onError?.({
      errorMessage: e?.response?.data?.message,
    });
  }
}

function* startRefund(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  const onError = action?.payload?.onError;
  try {
    const data = yield call(apis.refundCustomer, payload);
    yield put(actions.refundSuccess(data));

    onSuccess?.();
  } catch (e) {
    yield put(actions.refundError(e?.response?.data?.message));
    onError?.({
      errorMessage: e?.response?.data?.message,
    });
  }
}

function* resendWebhook(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  const onError = action?.payload?.onError;

  try {
    const data = yield call(apis.resendWebhook, payload);
    yield put(actions.resendWebhookSuccess(data));

    onSuccess?.();
  } catch (e) {
    yield put(actions.resendWebhookError(e?.response?.data?.message));
    onError?.({
      errorMessage: e?.response?.data?.message,
    });
  }
}

function* fetchBanks(action) {
  try {
    const { data, list_code } = yield call(apis.fetchBanks, action.payload);
    yield put(
      actions.fetchBanksSuccess({
        [action.payload]: { banks: data, list_code },
      })
    );
  } catch (e) {
    yield put(actions.fetchBanksError());
  }
}

function* verifyBankAccount(action) {
  try {
    const data = yield call(apis.verifyBankAccount, action.payload);
    yield put(actions.verifyBankAccountSuccess(data));
  } catch (e) {
    yield put(actions.verifyBankAccountError());
  }
}

function* carryOutBankTransfer(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  const onError = action?.payload?.onError;
  try {
    if (payload?.payload?.beneficiary_payout_ref) {
      yield call(apis.transferToBeneficiary, payload);
    } else {
      yield call(apis.bankTransfer, payload);
    }
    yield put(actions.transferSuccess());
    onSuccess?.();
    yield put(
      actions.fetchWallets({
        tribe_account_id: payload?.tribe_account_id,
      })
    );
  } catch (e) {
    yield put(actions.transferError());
    onError?.({
      errorMessage: e?.response?.data?.message,
    });
  }
}

function* fetchBeneficiaries(action) {
  try {
    const beneficiaries = yield call(apis.listAllBeneficiaries, action.payload);
    yield put(actions.fetchBeneficiariesSuccess(beneficiaries.data));
  } catch (e) {
    errorToast(
      "Error",
      "There was an error while fetching your beneficiaries. Please try again"
    );
    yield put(actions.fetchBeneficiariesError());
  }
}

function* exportWalletTransactions(action) {
  try {
    const { payload, callbackFunc } = action.payload;
    const response = yield call(apis.exportWalletTransactions, payload);
    yield put(actions.exportWalletTransactionsSuccess(response));
    callbackFunc();
  } catch (error) {
    yield put(actions.exportWalletTransactionsError());
  }
}

function* confirmPassword(action) {
  const payload = action?.payload?.payload;
  const onSuccess = action?.payload?.onSuccess;
  try {
    yield call(apis.confirmPassword, payload);
    yield put(actions.confirmPasswordSuccess());
    onSuccess?.();
  } catch (e) {
    yield put(actions.confirmPasswordError());
  }
}

export default function* walletSaga() {
  yield takeLatest(actions.createWallet.type, createWallet);
  yield takeLatest(actions.fetchWallets.type, fetchWallets);
  yield takeLatest(actions.fetchTransactions.type, fetchTransactions);
  yield takeLatest(
    actions.fetchSupportedCryptoWallets.type,
    fetchSupportedCryptoWallets
  );
  yield takeLatest(actions.getConversionRate.type, fetchConversionRate);
  yield takeLatest(actions.startSwap.type, swapCurrencies);
  yield takeLatest(actions.startRefund.type, startRefund);
  yield takeLatest(actions.resendWebhook.type, resendWebhook);

  yield takeLatest(actions.fetchBanks.type, fetchBanks);
  yield takeLatest(actions.verifyBankAccount.type, verifyBankAccount);
  yield takeLatest(actions.startTransfer.type, carryOutBankTransfer);
  yield takeLatest(actions.fetchBeneficiaries.type, fetchBeneficiaries);

  yield takeLatest(
    actions.exportWalletTransactions.type,
    exportWalletTransactions
  );
  yield takeLatest(actions.getMinerFee.type, getMinerFee);
  yield takeLatest(actions.cryptoPayout.type, cryptoPayout);
  yield takeLatest(
    actions.checkCryptoPayoutStatus.type,
    checkCryptoPayoutStatus
  );
  yield takeLatest(actions.confirmPassword.type, confirmPassword);
}
