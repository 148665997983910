import { useDispatch, useSelector } from "react-redux";
import walletSlice from "pages/Dashboard/Wallet/slice";
import transactionsSlice from "pages/Dashboard/Transactions/slice";
import { NAIRA_ABBR, walletConstants } from "utils/appConstant";
import { useAuth } from "hooks/auth";
import { handleToCurrencyValue } from "utils/data";

const { FIAT, CRYPTO } = walletConstants;
const { actions } = walletSlice;
const { actions: transactionAction } = transactionsSlice;
const useWallet = () => {
  const dispatch = useDispatch();
  const {
    selectedWalletDetails,
    cryptoWallets,
    fiatWallets,
    banks,
    conversionRate,
    verifyingBankAccount,
    account_details,
    transferring,
    transferSuccess,
    fetchingConversionRate,
    fetchingBanks,
    fetchingWallets,
    fetchBeneficiariesError,
    beneficiaries,
    supportedCryptoWallets,
    minerFee,
    isLoading,
    cryptoError,
    errorMessage,
    recipientAccounts,
    confirmingPassword,
    payoutReversalIndicatorClicked,
    refunding,
    resendWebhookLoading,
  } = useSelector((state) => state.wallet);

  const { account_tribe_ref } = useAuth();

  const supportedCoinTypes = new Set(
    supportedCryptoWallets.map((wallet) => wallet.coin_type)
  );

  const filteredCryptowallets = cryptoWallets.filter((wallet) =>
    supportedCoinTypes.has(wallet.crypto_wallet_type)
  );
  const allWallets = [...fiatWallets, ...cryptoWallets];
  const selectedWallet =
    selectedWalletDetails.type === CRYPTO
      ? cryptoWallets.find(
          (_) => _.crypto_wallet_id === selectedWalletDetails.id
        ) || {}
      : fiatWallets.find(
          (_) => _.fiat_wallet_id === selectedWalletDetails.id
        ) || {};

  const isSelectedWallet = (wallet, type) => {
    if (type === FIAT) {
      return (
        selectedWalletDetails.type === FIAT &&
        selectedWalletDetails.id === wallet.fiat_wallet_id
      );
    } else if (type === CRYPTO) {
      return (
        selectedWalletDetails.type === CRYPTO &&
        selectedWalletDetails.id === wallet.crypto_wallet_id
      );
    }
  };

  const setSelectedWallet = (wallet, type, callback, callLocation) => {
    if (type === FIAT) {
      dispatch(
        actions.setSelectedWalletDetails({
          id: wallet.fiat_wallet_id,
          type,
          callLocation,
        })
      );
    } else {
      dispatch(
        actions.setSelectedWalletDetails({
          id: wallet.crypto_wallet_id,
          type,
          callLocation,
        })
      );
    }
    if (callback) {
      callback();
    }
  };

  const resetWallets = () => {
    dispatch(actions.resetWallets());
    dispatch(transactionAction.resetTransactionsList());
  };

  const setPayoutReversalIndicatorClicked = (status) => {
    dispatch(actions.setPayoutReversalIndicatorClicked(status));
  };

  const defaultFiatCurrency = fiatWallets.find(
    (wallet) => wallet.wallet_type === "default"
  )?.currency;

  const defaultFiatWallet = fiatWallets.find(
    (wallet) => wallet.wallet_type === "default"
  );

  const getBalance = (currency, type) => {
    if (type === FIAT) {
      return fiatWallets.find(
        (_) => _.currency.toLowerCase() === currency?.toLowerCase()
      )?.available_balance;
    }
    if (type === CRYPTO) {
      return cryptoWallets.find(
        (_) => _.crypto_wallet_type.toLowerCase() === currency?.toLowerCase()
      )?.available_balance;
    }
  };

  const verifyBankAccount = (payload) => {
    dispatch(
      actions.verifyBankAccount({
        ...payload,
      })
    );
  };

  const clearAccountDetails = () => dispatch(actions.clearAccountDetails());
  const getConversionRate = (
    conversion_type,
    from_currency,
    to_currency,
    convert_amount,
    isCrypto
  ) => {
    let data = {};

    if (isCrypto) {
      data = {
        conversion_type,
        from_coin_type: from_currency,
        from_coin_amount: convert_amount,
        to_coin_type: handleToCurrencyValue(to_currency),
        tribeRef: account_tribe_ref,
      };
    } else {
      data = {
        conversion_type,
        from_currency,
        to_currency: handleToCurrencyValue(to_currency),
        convert_amount,
        tribeRef: account_tribe_ref,
      };
    }

    dispatch(actions.getConversionRate({ payload: data }));
  };
  const getCountryBanks = (country) => banks[country];
  const resetConversionRate = () => dispatch(actions.resetConversionRate());
  const resetCryptoError = () => dispatch(actions.resetCryptoError());
  const fetchWallets = (payload) => {
    dispatch(actions.fetchWallets(payload));
  };
  const resetWithdrawalState = (payload) => {
    dispatch(actions.resetWithdrawalState(payload));
  };

  const updateRecipientAccounts = ({ recipient, callback, intent }) =>
    dispatch(actions.updateRecipientAccounts({ recipient, callback, intent }));
  const firstFiatWallet = fiatWallets && fiatWallets[0];
  const ngnWallet = fiatWallets?.find((item) => item?.currency === NAIRA_ABBR);

  return {
    cryptoWallets,
    fiatWallets,
    allWallets,
    filteredCryptowallets,
    firstFiatWallet,
    ngnWallet,
    setSelectedWallet,
    selectedWalletDetails,
    isSelectedWallet,
    selectedWallet,
    defaultFiatCurrency,
    getBalance,
    banks,
    conversionRate,
    verifyingBankAccount,
    account_details,
    verifyBankAccount,
    transferring,
    transferSuccess,
    clearAccountDetails,
    getCountryBanks,
    getConversionRate,
    resetConversionRate,
    fetchingConversionRate,
    fetchingBanks,
    defaultFiatWallet,
    fetchWallets,
    fetchingWallets,
    fetchBeneficiariesError,
    beneficiaries,
    supportedCryptoWallets,
    minerFee,
    isLoading,
    cryptoError,
    errorMessage,
    resetCryptoError,
    recipientAccounts,
    updateRecipientAccounts,
    resetWithdrawalState,
    confirmingPassword,
    resetWallets,
    payoutReversalIndicatorClicked,
    setPayoutReversalIndicatorClicked,
    refunding,
    resendWebhookLoading,
  };
};

export default useWallet;
