import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import Modal from "components/General/Modal/Modal/Modal";
import useModal from "hooks/useModal";
import { TRANSACTION_MODAL_SCREENS } from "utils/modalScreens";
import TransactionDetails from "./TransactionDetails";
import useWindowDimensions from "hooks/useWindowDimensions";

const { TRANSACTION_DETAILS } = TRANSACTION_MODAL_SCREENS;
const TransactionDetailsModal = ({ active, toggler, details }) => {
  const { isMobile } = useWindowDimensions();
  const { transactionDetails, transactionDetailsLoading } = useSelector(
    (state) => state.transactions
  );
  const screens = [
    {
      name: TRANSACTION_DETAILS,
      header: "Transaction receipt",
      subHeader:
        transactionDetailsLoading || !transactionDetails?.pub_date
          ? ""
          : `Date: ${moment(transactionDetails?.pub_date).format(
              "DD MMM, YYYY h:mmA"
            )}`,
      content: (
        <TransactionDetails
          handleCloseModal={toggler}
          clearFilters={() => details?.clearFilters()}
          getTransaction={() => details?.getTransaction()}
        />
      ),
    },
  ];
  const { screenHistory, activeScreen, currentScreen, handleCloseModal } =
    useModal({
      details,
      toggler,
      screens,
    });

  return (
    <Modal
      size={"lg"}
      maxHeight={isMobile ? "80%" : "600px"}
      active={active && activeScreen}
      isSideModal
      noPadding
      closeOnClickOutside
      toggler={() => {
        handleCloseModal();
      }}
      modalClassName="overflow-y-auto overflow-x-hidden"
      headerClassName="pt-[20px] px-[20px]"
      activeScreen={activeScreen}
      screenHistory={screenHistory}
      screens={screens}
      header={currentScreen?.header}
      subHeader={currentScreen?.subHeader}
    />
  );
};

TransactionDetailsModal.propTypes = {
  active: PropTypes.bool,
  toggler: PropTypes.func,
  details: PropTypes.object,
};

export default TransactionDetailsModal;
