export const TEST_MODAL_SCREENS = {
  SCREEN_OPTIONS: "SCREEN_OPTIONS",
  SCREEN_ONE: "SCREEN_ONE",
  SCREEN_TWO: "SCREEN_TWO",
  SCREEN_THREE: "SCREEN_THREE",
  SCREEN_FOUR: "SCREEN_FOUR",
  SCREEN_FIVE: "SCREEN_FIVE",
  SCREEN_SIX: "SCREEN_SIX",
  ERROR_SCREEN: "ERROR_SCREEN",
  INNER_SCREEN_ONE: "INNER_SCREEN_ONE",
  INNER_SCREEN_TWO: "INNER_SCREEN_TWO",
  INNER_SCREEN_THREE: "INNER_SCREEN_THREE",
};

export const TRANSACTION_MODAL_SCREENS = {
  TRANSACTION_DETAILS: "TRANSACTION_DETAILS",
  EXPORT_TRANSACTIONS: "EXPORT_TRANSACTIONS",
  FILTER_TRANSACTIONS: "FILTER_TRANSACTIONS",
  REQUEST_STATEMENT: "REQUEST_STATEMENT",
};

export const WALLET_MODAL_SCREENS = {
  ADD_WALLET: "ADD_WALLET",
  WITHDRAW_OPTIONS: "WITHDRAW_OPTIONS",
  PRE_TRANSFER_DETAILS_FORM: "PRE_TRANSFER_DETAILS_FORM",
  TRANSFER_DETAILS_FORM: "TRANSFER_DETAILS_FORM",
  TRANSFER_AMOUNT_FORM: "TRANSFER_AMOUNT_FORM",
  WITHDRAW_OTP_FORM: "WITHDRAW_OTP_FORM",
  WITHDRAW_CONFIRMATION: "WITHDRAW_CONFIRMATION",
  PASSWORD_CONFIRMATION: "PASSWORD_CONFIRMATION",
  BENEFICIARY_SELECTION: "BENEFICIARY_SELECTION",
  WITHDRAW_SUCCESS: "WITHDRAW_SUCCESS",
  SWAP_OPTIONS: "SWAP_OPTIONS",
  SWAP_FORM: "SWAP_FORM",
  SWAP_SUCCESS: "SWAP_SUCCESS",
  REQUEST_STATEMENT: "REQUEST_STATEMENT",
  FUND_ACCOUNT: "FUND_ACCOUNT",
  WALLET_LIST: "WALLET_LIST",
};

export const SETTINGS_MODAL_SCREENS = {
  EDIT_EMAIL: "EDIT_EMAIL",
  EDIT_EMAIL_PASSWORD: "EDIT_EMAIL_PASSWORD",
  EDIT_EMAIL_OTP: "EDIT_EMAIL_OTP",
  EDIT_EMAIL_SUCCESS: "EDIT_EMAIL_SUCCESS",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",

  TWO_FA_TYPE_SELECTION: "TWO_FA_TYPE_SELECTION",
  VERIFY_SELECTED_2FA_OPTION: "VERIFY_SELECTED_2FA_OPTION",
  CODE_VERIFICATION: "CODE_VERIFICATION",
  DISABLE_TWO_FACTOR_AUTH: "DISABLE_TWO_FACTOR_AUTH",
  TWO_FA_TYPE_SELECTION_SUCCESS: "TWO_FA_TYPE_SELECTION_SUCCESS",
  REGENERATE_API: "REGENERATE_API",
  REGENERATE_API_SUCCESS: "REGENERATE_API_SUCCESS",
  ADD_WEBHOOK: "ADD_WEBHOOK",
  ADD_WEBHOOK_SUCCESS: "ADD_WEBHOOK_SUCCESS",
  TRANSACTIONS_ALERT: "TRANSACTIONS_ALERT",

  EDIT_NOTIFY: "EDIT_NOTIFY",
  EDIT_NOTIFY_SUCCESS: "EDIT_NOTIFY_SUCCESS",

  ADD_TEAM_MEMBER: "ADD_TEAM_MEMBER",
  EDIT_TEAM_MEMBER: "EDIT_TEAM_MEMBER",
  DELETE_TEAM_MEMBER: "DELETE_TEAM_MEMBER",
  ADD_TEAM_MEMBER_SUCCESS: "ADD_TEAM_MEMBER_SUCCESS",
};
export const PAYMENT_LINKS_MODAL_SCREENS = {
  PAYMENT_LINK_OPTIONS: "PAYMENT_LINK_OPTIONS",
  ADD_PAYMENT_LINK_FORM: "ADD_PAYMENT_LINK_FORM",
  ADD_PAYMENT_LINK_SUCCESS: "ADD_PAYMENT_LINK_SUCCESS",
  EDIT_PAYMENT_LINK_FORM: "EDIT_PAYMENT_LINK_FORM",

  OVERALL_PAYMENT_LINK_DETAILS: "OVERALL_PAYMENT_LINK_DETAILS",
  PAYMENT_LINK_DETAILS: "PAYMENT_LINK_DETAILS",
  PAYMENT_LINK_ACTIONS: "PAYMENT_LINK_ACTIONS",

  TRANSACTION_DETAILS: "TRANSACTION_DETAILS",
  ACTIVATE_PAYMENT_LINK_MOBILE: "ACTIVATE_PAYMENT_LINK_MOBILE",
};

export const VIRTUAL_ACCOUNTS_MODAL_SCREENS = {
  ADD_VIRTUAL_ACCOUNT_FORM: "ADD_VIRTUAL_ACCOUNT_FORM",
  ADD_VIRTUAL_ACCOUNT_TO_STORE: "ADD_VIRTUAL_ACCOUNT_TO_STORE",
  ADD_VIRTUAL_ACCOUNT_SUCCESS: "ADD_VIRTUAL_ACCOUNT_SUCCESS",
  ARCHIVE_VIRTUAL_ACCOUNT: "ARCHIVE_VIRTUAL_ACCOUNT",
  MOVE_VIRTUAL_ACCOUNT: "MOVE_VIRTUAL_ACCOUNT",
  PIN_VIRTUAL_ACCOUNT: "PIN_VIRTUAL_ACCOUNT",
  ACTIVITY_ACTIONS: "ACTIVITY_ACTIONS",

  ADD_LOCATION_INTRO: "ADD_LOCATION_INTRO",
  ADD_LOCATION_FORM: "ADD_LOCATION_FORM",
  ADD_LOCATION_STORE_FORM: "ADD_LOCATION_STORE_FORM",
  ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",

  ADD_STORE_FORM: "ADD_STORE_FORM",
  ADD_VIRTUAL_ACCOUNTS_TO_STORE: "ADD_VIRTUAL_ACCOUNTS_TO_STORE",
  ADD_STORE_SUCCESS: "ADD_STORE_SUCCESS",

  ACTIVITY_FEED: "ACTIVITY_FEED",
  OVERALL_ACTIVITY_FEED: "OVERALL_ACTIVITY_FEED",
  VIRTUAL_ACCOUNT_DETAILS: "VIRTUAL_ACCOUNT_DETAILS",
  VIRTUAL_ACCOUNT_ACTIONS: "VIRTUAL_ACCOUNT_ACTIONS",
  TRANSACTION_DETAILS: "TRANSACTION_DETAILS",

  ADD_TEAM_MEMBER_FORM: "ADD_TEAM_MEMBER_FORM",
  ADD_TEAM_MEMBER_SUCCESS: "ADD_TEAM_MEMBER_SUCCESS",
  TEAM_MEMBER_QR_CODE: "TEAM_MEMBER_QR_CODE",
  UNLINK_TEAM_MEMBER: "UNLINK_TEAM_MEMBER",
};

export const COMPLIANCE_MODAL_SCREENS = {
  PERMISSION: "PERMISSION",
  COMPLIANCE: "COMPLIANCE",
  IMAGE: "IMAGE",
};
export const OVERVIEW_MODAL_SCREENS = {
  DATE_RANGE: "DATE_RANGE",
  ADD_BUSINESS: "ADD_BUSINESS",
};
