import React from "react";
import PropTypes from "prop-types";

export const RefundCustomer = function RefundCustomer({ transferDetails }) {
  const { amount, account_name } = transferDetails;

  return (
    <div className="overflow-hidden my-2 py-8 px-6 flex flex-col justify-center items-center w-full">
      <p className="text-center text-lg text-grey-text">
        You are about to refund <span className="text-black">{amount}</span> to
        <span className="text-black"> {account_name}</span>. Kindly click
        confirm to continue transaction.
      </p>
    </div>
  );
};
RefundCustomer.propTypes = {
  transferDetails: PropTypes.object,
};
