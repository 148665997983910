import { createSlice } from "@reduxjs/toolkit";
import { warningToast } from "components/General/Toast/Toast";
import { isEmpty } from "lodash";
import { walletConstants } from "utils/appConstant";

const { CRYPTO, FIAT } = walletConstants;

const initialState = {
  creatingWallet: false,
  fetchingWallets: true,
  error: false,
  cryptoError: false,
  errorMessage: "",
  fiatWallets: [],
  cryptoWallets: [],
  selectedWalletDetails: {},
  transactions: [],
  loadingTransactions: false,
  fetchingSupportedCryptoWallets: false,
  supportedCryptoWallets: [],
  createdWallet: {},
  conversionRate: {},
  fetchingConversionRate: false,
  swapping: false,
  swapError: false,
  swapSuccess: false,

  refunding: false,
  refundError: false,
  refundSuccess: false,

  resendWebhookLoading: false,
  resendWebhookError: false,
  resendWebhookSuccess: false,

  fetchingBanks: false,
  banks: [],
  verifyingBankAccount: false,
  account_details: {},
  transferring: false,
  transferSuccess: false,
  businessAccountDetails: {},
  fetchingBeneficiaries: false,
  fetchBeneficiariesError: false,
  exportingTransactions: false,
  exportTransactionsResults: "",
  beneficiaries: [],
  isLoading: false,
  minerFee: {},
  recipientAccounts: [],
  walletFunded: null,

  confirmingPassword: false,
  payoutReversalIndicatorClicked: false,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    updateRecipientAccounts: (state, action) => {
      const { recipient, callback, intent } = action.payload;
      if (!recipient) {
        return;
      }
      const match = state.recipientAccounts?.find(
        (r) => r?.account_number === recipient?.account_number
      );
      if (!match) {
        state.recipientAccounts = [...state.recipientAccounts, recipient];
        state.account_details = {};
        callback?.();
      } else {
        if (intent === "add") {
          warningToast(
            "Error!",
            "You cannot add the same account number twice"
          );
          return;
        }
        state.recipientAccounts = state.recipientAccounts?.filter(
          (r) => r.account_number !== recipient.account_number
        );
      }
    },
    setPayoutReversalIndicatorClicked: (state, action) => {
      state.payoutReversalIndicatorClicked = action.payload;
    },
    setWalletFunded: (state, action) => {
      state.walletFunded = action.payload;
    },
    createWallet: (state, action) => {
      state.creatingWallet = true;
      state.createdWallet = {};
    },
    createWalletError: (state) => {
      state.creatingWallet = false;
    },
    createWalletSuccess: (state, action) => {
      state.creatingWallet = false;
      state.error = false;
      state.createdWallet = action.payload;
    },
    fetchWallets: (state) => {
      state.fetchingWallets = true;
      state.fiatWallets = [];
      state.cryptoWallets = [];
      state.createdWallet = {};
      // state.selectedWalletDetails = {};
    },
    fetchWalletsError: (state) => {
      state.fetchingWallets = false;
      state.error = true;
    },
    fetchWalletsSuccess: (state, action) => {
      const { fiat, crypto, fiat_wallet_id, coin_wallet_id } = action.payload;

      state.fetchingWallets = false;
      state.fiatWallets = fiat;
      state.cryptoWallets = crypto;
      state.error = false;
      if (
        isEmpty(state.selectedWalletDetails) ||
        action.payload.refresh_selected_wallet
      ) {
        if (!isEmpty(fiat)) {
          state.selectedWalletDetails = {
            type: FIAT,
            id:
              Number(fiat_wallet_id) ||
              fiat?.find((wallet) => wallet.wallet_type === "default")
                .fiat_wallet_id,
          };
        } else {
          if (!isEmpty(crypto)) {
            state.selectedWalletDetails = {
              type: CRYPTO,
              id:
                Number(coin_wallet_id) ||
                crypto?.find((wallet) => wallet.wallet_type === "default")
                  .crypto_wallet_id,
            };
          }
        }
      }
    },
    resetWallets: (state) => {
      state.fetchingWallets = true;
      state.fiatWallets = [];
      state.cryptoWallets = [];
      state.selectedWalletDetails = {};
    },
    fetchTransactions: (state) => {
      state.loadingTransactions = true;
    },
    fetchTransactionsError: (state) => {
      state.loadingTransactions = false;
      state.error = true;
      state.transactions = [];
    },
    fetchTransactionsSuccess: (state, action) => {
      state.transactions = action.payload;
      state.loadingTransactions = false;
      state.error = false;
    },

    getMinerFee: (state) => {
      state.isLoading = true;
    },
    getMinerFeeError: (state, action) => {
      state.isLoading = false;
      state.cryptoError = true;
      state.errorMessage = action?.payload?.response?.data?.message;
      state.minerFee = {};
    },
    getMinerFeeSuccess: (state, action) => {
      state.minerFee = action.payload;
      state.isLoading = false;
      state.cryptoError = false;
    },

    cryptoPayout: (state) => {
      state.isLoading = true;
    },
    cryptoPayoutError: (state, action) => {
      state.isLoading = false;
      state.cryptoError = true;
      state.errorMessage = action?.payload?.response?.data?.message;
    },
    cryptoPayoutSuccess: (state, action) => {
      state.cryptoError = false;
    },

    checkCryptoPayoutStatus: (state) => {
      state.isLoading = true;
    },
    checkCryptoPayoutStatusError: (state, action) => {
      state.isLoading = false;
      state.cryptoError = true;
      state.errorMessage = action?.payload?.response?.data?.message;
    },
    checkCryptoPayoutStatusSuccess: (state) => {
      state.cryptoError = false;
      state.isLoading = false;
      state.transferSuccess = true;
      state.errorMessage = "";
    },
    resetCryptoError: (state) => {
      state.cryptoError = false;
      state.isLoading = false;
      state.errorMessage = "";
    },

    setSelectedWalletDetails: (state, action) => {
      state.selectedWalletDetails = action.payload;
    },
    fetchSupportedCryptoWallets: (state) => {
      state.fetchingSupportedCryptoWallets = true;
    },
    fetchSupportedCryptoWalletsError: (state) => {
      state.fetchingSupportedCryptoWallets = false;
      state.error = true;
    },
    fetchSupportedCryptoWalletsSuccess: (state, action) => {
      state.fetchingSupportedCryptoWallets = false;
      state.error = false;
      state.supportedCryptoWallets = action.payload;
    },
    getConversionRate: (state) => {
      state.conversionRate = {};
      state.fetchingConversionRate = true;
    },
    getConversionRateSuccess: (state, action) => {
      state.conversionRate = action.payload;
      state.error = false;
      state.fetchingConversionRate = false;
    },
    getConversionRateError: (state) => {
      state.error = true;
      state.fetchingConversionRate = false;
    },

    startSwap: (state) => {
      state.swapping = true;
    },
    swapSuccess: (state, action) => {
      state.swapError = false;
      state.swapping = false;
      state.swapSuccess = action.payload;
    },
    swapError: (state, action) => {
      state.swapError = action.payload?.message || true;
      state.swapping = false;
      state.swapSuccess = false;
    },
    resetSwapState: (state) => {
      state.swapError = false;
      state.swapSuccess = false;
      state.swapping = false;
    },

    startRefund: (state) => {
      state.refunding = true;
    },
    refundSuccess: (state, action) => {
      state.refundError = false;
      state.refunding = false;
      state.refundSuccess = action.payload;
    },
    refundError: (state, action) => {
      state.refundError = action.payload?.message || true;
      state.refunding = false;
      state.refundSuccess = false;
    },

    resendWebhook: (state) => {
      state.resendWebhookLoading = true;
    },
    resendWebhookSuccess: (state, action) => {
      state.resendWebhookError = false;
      state.resendWebhookLoading = false;
      state.resendWebhookSuccess = action.payload;
    },
    resendWebhookError: (state, action) => {
      state.resendWebhookError = action.payload?.message || true;
      state.resendWebhookLoading = false;
      state.resendWebhookSuccess = false;
    },
    fetchBanks: (state) => {
      state.fetchingBanks = true;
    },
    fetchBanksSuccess: (state, action) => {
      return {
        ...state,
        fetchingBanks: false,
        error: false,
        banks: { ...state.banks, ...action.payload },
      };
    },
    fetchBanksError: (state) => {
      state.fetchingBanks = false;
      state.error = true;
    },
    verifyBankAccount: (state) => {
      state.verifyingBankAccount = true;
    },
    verifyBankAccountSuccess: (state, action) => {
      state.account_details = action.payload;
      state.verifyingBankAccount = false;
    },
    verifyBankAccountError: (state) => {
      state.verifyingBankAccount = false;
      state.account_details = {};
    },
    resetWithdrawalState: (state) => {
      state.verifyingBankAccount = false;
      state.fetchingBanks = false;
      state.error = false;
      state.transferring = false;
      state.transferSuccess = false;
      state.account_details = {};
      state.recipientAccounts = [];
    },
    startTransfer: (state) => {
      state.transferring = true;
    },
    transferError: (state) => {
      state.error = true;
      state.transferring = false;
      state.transferSuccess = false;
    },
    transferSuccess: (state) => {
      state.transferring = false;
      state.error = false;
      state.transferSuccess = true;
    },
    clearAccountDetails: (state) => {
      state.account_details = {};
    },
    resetConversionRate: (state) => {
      state.conversionRate = {};
    },

    fetchBeneficiariesError: (state) => {
      state.fetchingBeneficiaries = false;
      state.fetchBeneficiariesError = true;
    },
    fetchBeneficiariesSuccess: (state, action) => {
      state.fetchBeneficiariesError = false;
      state.beneficiaries = action.payload;
      state.fetchingBeneficiaries = false;
    },
    fetchBeneficiaries: (state) => {
      state.fetchingBeneficiaries = true;
      state.fetchBeneficiariesError = false;
    },

    exportWalletTransactions: (state) => {
      state.exportingTransactions = true;
      state.exportTransactionsResults = "";
    },
    exportWalletTransactionsSuccess: (state, action) => {
      state.exportingTransactions = false;
      state.exportTransactionsResults = action.payload;
    },
    exportWalletTransactionsError: (state, action) => {
      state.exportingTransactions = false;
      state.exportTransactionsResults = "";
    },
    resetExportWalletTransactions: (state) => {
      state.exportingTransactions = false;
      state.exportTransactionsResults = "";
    },

    confirmPassword: (state) => {
      state.confirmingPassword = true;
    },
    confirmPasswordError: (state) => {
      state.confirmingPassword = false;
    },
    confirmPasswordSuccess: (state) => {
      state.confirmingPassword = false;
    },
  },
});

export default walletSlice;
