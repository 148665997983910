import { call, put, takeLatest } from "redux-saga/effects";
import apis from "services/transaction";
import transactionsSlice from ".";

const { actions } = transactionsSlice;

function* getTransactionsList(action) {
  try {
    const response = yield call(apis.listAndFilterTransactions, action.payload);
    console.log("hello");
    yield put(actions.getTransactionsListSuccess(response));
  } catch (error) {
    yield put(
      actions.getTransactionsListError(
        error?.response?.data || "There was an error"
      )
    );
  }
}

function* getReversedTransactionsList(action) {
  try {
    const response = yield call(apis.listAndFilterTransactions, action.payload);
    yield put(actions.getReversedTransactionsListSuccess(response));
  } catch (error) {
    yield put(
      actions.getReversedTransactionsListError(
        error?.response?.data || "There was an error"
      )
    );
  }
}

function* getTransactionDetails(action) {
  try {
    const { data } = yield call(apis.getTransactionDetails, action.payload);
    console.log("data", data);
    yield put(actions.getTransactionDetailsSuccess(data));
  } catch (error) {
    yield put(
      actions.getTransactionDetailsError(
        error?.response?.data || "There was an error"
      )
    );
  }
}

function* searchTransactions(action) {
  try {
    const response = yield call(apis.searchTransactions, action.payload);
    yield put(actions.searchTransactionsSuccess(response));
  } catch (error) {
    yield put(actions.searchTransactionsError());
  }
}

function* exportAccountTransactions(action) {
  try {
    const { payload, callbackFunc } = action.payload;
    const response = yield call(apis.exportAccountTransactions, payload);
    yield put(actions.exportAccountTransactionsSuccess(response));
    callbackFunc();
  } catch (error) {
    yield put(actions.exportAccountTransactionsError());
  }
}

export function* transactionsSaga() {
  yield takeLatest(actions.getTransactionsList.type, getTransactionsList);
  yield takeLatest(
    actions.getReversedTransactionsList.type,
    getReversedTransactionsList
  );

  yield takeLatest(actions.getTransactionDetails.type, getTransactionDetails);
  yield takeLatest(actions.searchTransactions.type, searchTransactions);
  yield takeLatest(
    actions.exportAccountTransactions.type,
    exportAccountTransactions
  );
}
