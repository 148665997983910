/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { Button } from "components/General/Button";
import { ReactComponent as Export } from "assets/icons/Export/export-blue.svg";
import { ReactComponent as Filter } from "assets/icons/Filter/filter.svg";
import SearchBar from "components/General/Searchbar/SearchBar";
import useWindowDimensions from "hooks/useWindowDimensions";

function TableManipulation({
  placeholder,
  onSearchClick,
  onSearchChange,
  searchValue,
  onFilter,
  onExport,
  className,
  searchLoading,
  manualSearch,
  searchClass = "hidden lg:flex",
  filtersClassName,
  filterButtonProps,
  exportButtonProps,
}) {
  const { width } = useWindowDimensions();
  return (
    <div
      className={clsx(
        "flex flex-row justify-start items-center h-fit w-full rounded-lg gap-3",
        className
      )}
    >
      {onSearchChange && (
        <div className="w-full relative">
          <SearchBar
            manualSearch={manualSearch}
            placeholder={placeholder}
            onClick={onSearchClick}
            onChange={onSearchChange}
            value={searchValue}
            className={searchClass}
            isLoading={searchLoading}
          />
        </div>
      )}
      {(onFilter || onExport) && (
        <div
          className={clsx(
            "flex justify-between items-center space-x-3 w-full md:w-fit",
            filtersClassName
          )}
        >
          {onFilter && (
            <Button
              text="Filter"
              whiteBg
              icon={<Filter className="stroke-current" />}
              onClick={onFilter}
              fullWidth={
                (!onExport && !onSearchChange && width < 640) ||
                filterButtonProps?.fullWidth
              }
              {...filterButtonProps}
            />
          )}

          {onExport && (
            <Button
              text="Export"
              icon={<Export className="stroke-current text-blue" />}
              isOutline
              onClick={onExport}
              fullWidth={
                (!onFilter && !onSearchChange && width < 640) ||
                exportButtonProps?.fullWidth
              }
              {...exportButtonProps}
            />
          )}
        </div>
      )}
    </div>
  );
}

TableManipulation.propTypes = {
  placeholder: PropTypes.string,
  searchValue: PropTypes.string,
  onSearchClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  onFilter: PropTypes.func,
  onExport: PropTypes.func,
  className: PropTypes.string,
  searchClass: PropTypes.string,
  searchLoading: PropTypes.bool,
  manualSearch: PropTypes.bool,
  filtersClassName: PropTypes.string,
  filterButtonProps: PropTypes.object,
  exportButtonProps: PropTypes.object,
};

export default TableManipulation;
