import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const useLocationParams = ({
  onAddParam = () => {},
  onRemoveParam = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentParams = new URLSearchParams(window.location.search);
  // Parse the query string
  const searchParams = new URLSearchParams(location.search);
  const handleGetParam = (param) => searchParams.get(param);

  const handleAddParam = (param, value) => {
    onAddParam?.();
    currentParams.set(param, value);
    navigate(`${window.location.pathname}?${currentParams.toString()}`);
  };
  const handleRemoveParam = (param, preventReload = false) => {
    onRemoveParam?.();
    currentParams.delete(param);
    if (preventReload) {
      return;
    }
    // Update the URL without reloading the page
    navigate(`${location.pathname}?${currentParams.toString()}`);
  };

  return {
    currentParams,
    searchParams,
    handleRemoveParam,
    handleAddParam,
    handleGetParam,
  };
};

useLocationParams.propTypes = {
  onRemoveParam: PropTypes.func,
  onAddParam: PropTypes.func,
};

export default useLocationParams;
