/* eslint-disable react/prop-types */
import ReactPaginate from "react-paginate";
import { ReactComponent as ArrowRight } from "assets/icons/Arrow/arrow-right-black.svg";
import { numberWithCommas } from "utils/formatter";
import { PaginationWrapper } from "./table.style";
import { Button } from "../Button";

const Pagination = ({ pageCount, onPageChange, currentPage }) => {
  const pageCountNumber = Math.ceil(pageCount);
  return pageCount && pageCount > 1 ? (
    <PaginationWrapper>
      <ReactPaginate
        className="react-paginate"
        pageClassName="pagination-page-item"
        activeClassName="active-page"
        breakLabel="..."
        pageLabelBuilder={(page) => (
          <div key={page}>
            Page {numberWithCommas(page)} of {numberWithCommas(pageCountNumber)}
          </div>
        )}
        nextLabel={
          <Button
            text="Next"
            whiteBg
            rightIcon={<ArrowRight className="scale-90" />}
            textClass="text-black basier-medium"
          />
        }
        onPageChange={(page) => onPageChange(page.selected + 1)} // Library uses zero index for page number
        pageRangeDisplayed={0}
        marginPagesDisplayed={0}
        pageCount={pageCountNumber}
        previousLabel={
          <Button
            text="Previous"
            whiteBg
            icon={<ArrowRight className="rotate-180 scale-90" />}
            textClass="text-black basier-medium"
          />
        }
        disabledClassName="opacity-30"
        forcePage={currentPage - 1} // Library uses zero index for page number
        breakClassName="page-item-break"
        renderOnZeroPageCount={null}
      />
    </PaginationWrapper>
  ) : null;
};
export default Pagination;
