import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { hideSideNav, showSideNav } from "utils/functions";
import { Button } from "components/General/Button";
import ModalBody from "../ModalBody/ModalBody";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalFooter from "../ModalFooter/ModalFooter";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import useWindowDimensions from "hooks/useWindowDimensions";

const Modal = ({
  active,
  toggler,
  size,
  className,
  maxHeight = "650px",
  minHeight,
  containerClassName = "overflow-y-auto",
  modalClassName,
  isSideModal,
  screens,
  activeScreen,
  screenHistory = [],
  header,
  subHeader,
  showErrorScreen,
  errorTitle,
  errorMessage,
  errorButtonProps,
  noPadding,
  isSuccess,
  backButtonProps,
  actionButtonProps,
  headerClassName,
  bodyClassName,
  closeOnClickOutside,
  onBackClick,
  hideToggler,
  stackButtonsHorizontally,
  showActionButtonOnHeader,
  useSuccessBanner,
  onRefresh,
  modalBodyClassName,
}) => {
  const [showModal, setShowModal] = useState(active);
  const { isTab } = useWindowDimensions();
  const handleToggler = () => {
    if (active) {
      setShowModal(false);
      setTimeout(toggler, 300);
      return;
    }
    setShowModal(true);
    toggler();
  };

  const modalSize = showErrorScreen ? "md" : size;
  const modalClassNames = {
    "w-full md:max-w-[86%] md:max-h-[89%] md:w-[86%] md:h-[89%]":
      modalSize === "xxl",
    "w-full md:max-w-[600px]": modalSize === "xl",
    "w-full md:max-w-lg": modalSize === "lg",
    "w-full md:max-w-md": modalSize === "md",
    "w-full md:max-w-sm": modalSize === "sm",
    "w-full md:max-w-[700px] lg:max-w-[850px]": modalSize === "date-lg",
    "w-full md:w-fit": !modalSize,
    "opacity-100 translate-y-0 bottom-0 pointer-events-auto animate-slide-up":
      showModal && !isSideModal,
    "md:translate-y-1/4 translate-y-[1000px] opacity-0 pointer-events-none bottom-0 animate-slide-down":
      !showModal && !isSideModal,

    "opacity-100 translate-x-0 bottom-0 pointer-events-auto animate-slide-left":
      showModal && isSideModal && !isTab,
    "md:translate-x-1/4 translate-x-[1000px] opacity-0 pointer-events-none bottom-0 animate-slide-right":
      !showModal && isSideModal && !isTab,

    "opacity-100 translate-x-0 bottom-0 pointer-events-auto animate-slide-up":
      showModal && isSideModal && isTab,
    "md:translate-x-1/4 translate-x-[1000px] opacity-0 pointer-events-none bottom-0 animate-slide-down":
      !showModal && isSideModal && isTab,
    "py-[24px]": !noPadding && !isSuccess,
    [className]: className,
  };

  useEffect(() => {
    if (showModal) {
      hideSideNav();
      document.body.style.overflow = "hidden";
    } else {
      showSideNav();
    }
  }, [showModal]);

  const getTransitionClass = (screenName) => {
    const currentIndex = screens?.findIndex(
      (screen) => screen.name === screenName
    );
    const prevIndex = screens?.findIndex(
      (screen) => screen.name === screenHistory[screenHistory?.length - 2]
    );

    if (screenName === activeScreen) {
      if (showErrorScreen) {
        return "translate-x-0 !max-h-0 opacity-0";
      }
      return "translate-x-0";
    }
    if (currentIndex > prevIndex || prevIndex === -1)
      return "translate-x-full !max-h-0 opacity-0"; // Next pages move from right to left
    return "-translate-x-full !max-h-0 opacity-0"; // Previous pages move from left to right
  };
  const allowCloseOnClickOutside = closeOnClickOutside || isSuccess;
  return (
    <div
      className={classNames(
        `h-screen overflow-hidden w-full fixed !m-0 flex items-start backdrop z-[9998] bottom-0 md:top-0 left-0`,
        containerClassName,
        {
          "transition-all duration-100 ease-in-out opacity-100 pointer-events-auto":
            showModal,
        },
        {
          "transition-all duration-300 ease-in-out opacity-0 !pointer-events-none":
            !showModal,
        },
        {
          "py-8 justify-center": !isSideModal,
        },
        {
          "py-8 md:py-0 justify-end": isSideModal,
        }
      )}
    >
      <div
        style={{ maxHeight, minHeight }}
        className={classNames(
          `!absolute md:!relative flex flex-col justify-start bg-white rounded-bl-none rounded-br-none  w-full transition-all z-[900] duration-300 ease-in-out`,

          {
            "md:min-h-[100vh] overflow-y-auto overflow-x-hidden": isSideModal,
            "!h-full": modalSize === "xxl",
            "h-fit": modalSize !== "xxl",
          },
          {
            "md:max-h-[670px] mt-4 md:rounded-bl-lg md:rounded-br-lg rounded-lg":
              !isSideModal,
          },
          { ...modalClassNames },
          modalClassName
        )}
      >
        {showErrorScreen ? null : (
          <ModalHeader
            isSideModal={isSideModal}
            isSuccess={isSuccess}
            toggler={handleToggler}
            onRefresh={onRefresh}
            header={header}
            subHeader={subHeader}
            noPadding={noPadding}
            className={headerClassName}
            onBackClick={onBackClick}
            screenHistory={screenHistory}
            hideToggler={hideToggler}
            actionButtonProps={actionButtonProps}
            showActionButtonOnHeader={showActionButtonOnHeader}
            useSuccessBanner={useSuccessBanner}
          />
        )}

        <div
          className={classNames(
            "flex flex-col justify-start items-start transform transition-transform duration-300 ease-linear z-[9]",
            {
              "transform -translate-y-[calc(100%-150px)] !max-h-0 !opacity-0 z-[-99]":
                !showErrorScreen,
              "transform translate-y-0 bg-white  p-[20px]": showErrorScreen,
            }
          )}
        >
          {showErrorScreen ? (
            <ErrorScreen {...{ errorTitle, errorMessage, errorButtonProps }} />
          ) : null}
        </div>
        <ModalBody
          className={classNames(
            "overflow-hidden",
            { "px-4 md:px-[20px]": !noPadding },
            bodyClassName
          )}
        >
          {screens?.map((screen) =>
            showModal || screen.name === activeScreen ? (
              <div
                key={screen.name}
                className={`${modalBodyClassName} overflow-x-hidden overflow-y-auto transform transition-all duration-200 ease-linear w-full ${getTransitionClass(
                  screen.name
                )}`}
              >
                {screen.content}
              </div>
            ) : null
          )}
        </ModalBody>
        {!showActionButtonOnHeader &&
        (backButtonProps?.onClick || actionButtonProps?.onClick) &&
        !showErrorScreen ? (
          <ModalFooter>
            <div
              className={classNames(
                "flex items-center justify-between w-full gap-4",
                {
                  "flex-col-reverse": stackButtonsHorizontally,
                }
              )}
            >
              {backButtonProps?.text ? (
                <Button
                  textColor="text-black"
                  whiteBg
                  fullWidth
                  {...backButtonProps}
                />
              ) : null}
              {actionButtonProps?.text ? (
                <Button fullWidth {...actionButtonProps} />
              ) : null}
            </div>
          </ModalFooter>
        ) : null}
        {/* {children} */}
      </div>

      <div
        onClick={() => {
          if (!allowCloseOnClickOutside) {
            return;
          }
          handleToggler();
        }}
        className="fixed top-0 left-0 h-screen w-full !my-0"
      ></div>
    </div>
  );
};

Modal.propTypes = {
  active: PropTypes.bool,
  toggler: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.elementType,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  modalClassName: PropTypes.string,
  isSideModal: PropTypes.bool,
  screens: PropTypes.array,
  activeScreen: PropTypes.string,
  screenHistory: PropTypes.array,
  header: PropTypes.any,
  subHeader: PropTypes.string,
  showErrorScreen: PropTypes.bool,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
  errorButtonProps: PropTypes.object,
  isSuccess: PropTypes.bool,
  backButtonProps: PropTypes.object,
  actionButtonProps: PropTypes.object,
  closeOnClickOutside: PropTypes.bool,
  onBackClick: PropTypes.func,
  onRefresh: PropTypes.func,
  hideToggler: PropTypes.bool,
  stackButtonsHorizontally: PropTypes.bool,
  showActionButtonOnHeader: PropTypes.bool,
  useSuccessBanner: PropTypes.bool,
  modalBodyClassName: PropTypes.string,
};

export default Modal;
