/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Chart from "react-apexcharts";
import FilterModal from "./FilterModal";
import PropTypes from "prop-types";
import ActiveFilter from "components/General/ActiveFilter";
import { ReactComponent as Loader } from "assets/icons/Loader/Loader.svg";
import _ from "lodash";
import { setDefaultBarOptions } from "utils/defaultChartOptions";
import { numberWithCommas } from "utils/formatter";
import { hasValue } from "utils/validations";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import useTableFilter from "hooks/tableFilter";
import dateConstants from "utils/dateConstants";
import { handleBalanceType } from "utils/functions";
import { MERCHANTS_MODAL_TYPES, STAT_DATE_TYPE } from "utils/appConstant";
import transactionsSlice from "../slice";

const { MECHANT_TRANSACTIONS_FILTER } = MERCHANTS_MODAL_TYPES;
const defaultFilters = {
  start_date: "",
  end_date: "",
  tx_type: "",
  cur_type: "",
  stat_date_type: "",
};

const requiredFilters = {
  start_date: "2022-01-01",
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  tx_type: "credit",
  cur_type: "fiat",
  stat_date_type: "monthly",
};

const { startOfMonth, endOfMonth } = dateConstants;
const { MONTHLY, WEEKLY } = STAT_DATE_TYPE;
const { actions } = transactionsSlice;
const InflowOverview = ({
  tribe_account_id,
  modalVisible,
  setModalVisible,
}) => {
  const dispatch = useDispatch();
  const { graphData, loading, merchantDetails } = useSelector(
    (state) => state.shoppers
  );

  const [barSeries, setBarSeries] = useState([]);
  const [barOptions, setBarOptions] = useState({ ...setDefaultBarOptions("") });
  const [currentPage, setCurrentPage] = useState(1);

  const {
    filterInput,
    filterData,
    setFilterInput,
    setFilterData,
    handleFilter,
    clearFilters,
    onRemoveFilter,
  } = useTableFilter({
    defaultFilters,
    setFilterModal: setModalVisible,
    currentPage,
    setCurrentPage,
  });

  const [revenue, setRevenue] = useState(0);
  const [filterValues, setValues] = useState(
    moment(
      hasValue(filterInput.start_date)
        ? filterInput.start_date
        : requiredFilters.start_date
    ).format("DD MMM yyyy") +
      " - " +
      moment(
        hasValue(filterInput.end_date)
          ? filterInput.end_date
          : requiredFilters.end_date
      ).format("DD MMM yyyy")
  );
  const [balanceType, setBalanceType] = useState(null);

  useEffect(() => {
    dispatch(
      actions.getMerchantTransactionsGraphData({
        tribe_account_id,
        start_date: requiredFilters.start_date,
        end_date: requiredFilters.end_date,
        stat_date_type: requiredFilters.stat_date_type,
        cur_type: requiredFilters.cur_type,
        tx_type: requiredFilters.tx_type,
      })
    );
  }, []);

  const start_date = useMemo(
    () =>
      hasValue(filterData.start_date)
        ? filterData.start_date
        : requiredFilters.start_date,
    [filterData]
  );
  const end_date = useMemo(
    () =>
      hasValue(filterData.end_date)
        ? filterData.end_date
        : requiredFilters.end_date,
    [filterData]
  );

  const stat_date_type =
    moment(start_date).isBetween(startOfMonth, endOfMonth) &&
    moment(end_date).isBetween(startOfMonth, endOfMonth)
      ? WEEKLY
      : MONTHLY;

  useEffect(() => {
    let categories = [];
    let data = [];

    const fiat_wallet_details = merchantDetails?.fiat_wallet_details || [];
    const crypto_wallet_details = merchantDetails?.crypto_wallet_details || [];
    const businessWallets = fiat_wallet_details.concat(crypto_wallet_details);

    if (stat_date_type === "monthly") {
      categories = Object.keys(graphData);
      data = categories?.map((key) =>
        graphData[key].length
          ? graphData[key].reduce((a, b) => {
              return a + Number(b.amount);
            }, 0)
          : 0
      );
    } else {
      categories = _.isArray(graphData)
        ? graphData?.map((item) => `Week ${item.week}`)
        : [];
      data = _.isArray(graphData)
        ? graphData?.map((item) =>
            item.week_flow?.length
              ? item.week_flow.reduce((a, b) => {
                  return a + Number(b.amount);
                }, 0)
              : 0
          )
        : [];
    }

    const revenue = data.reduce((a, b) => {
      return a + b;
    }, 0);

    const balType = handleBalanceType(businessWallets, {});
    setBalanceType(balType);

    setRevenue(revenue);
    setBarOptions({
      ...setDefaultBarOptions(""),
      xaxis: {
        categories,
      },
    });
    setBarSeries([
      {
        name: "Inflow Marchant Transactions",
        data,
      },
    ]);
  }, [graphData]);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, filterData]);

  const fetchTransactions = () => {
    dispatch(
      actions.getMerchantTransactionsGraphData({
        tribe_account_id,
        start_date,
        end_date,
        stat_date_type,
        cur_type: hasValue(filterInput.cur_type)
          ? filterInput.cur_type.value
          : requiredFilters.cur_type,
        tx_type: hasValue(filterInput.tx_type)
          ? filterInput.tx_type.value
          : requiredFilters.tx_type,
      })
    );
    dispatch(
      actions.getMerchantTransactions({
        payload: {
          tribe_account_id,
          start_date,
          end_date,
          ...(hasValue(filterInput.tx_type) && {
            tx_type: filterInput.tx_type.value,
          }),
        },
        page: 1,
      })
    );
  };

  const filterRange = [
    {
      label: "This week",
      startDate: new Date(dateConstants?.startOfWeek),
      endDate: new Date(dateConstants?.endOfWeek),
      value: "this_week",
      key: "selection",
    },
    {
      label: "This month",
      startDate: new Date(dateConstants?.startOfMonth),
      endDate: new Date(dateConstants?.endOfMonth),
      value: "custom",
      key: "selection",
    },
    {
      label: "All",
      startDate: new Date(dateConstants?.firstDay),
      endDate: new Date(dateConstants?.today),
      value: "custom",
      key: "selection",
    },
  ];

  const containsActiveFilter = () =>
    Object.keys(filterData).filter(
      (item) => filterData[item] && filterData[item] !== ""
    );

  const renderFilters = () => {
    if (filterData) {
      return containsActiveFilter().map((item) => {
        const hasChanged = defaultFilters[item] !== filterData[item];
        if (hasChanged) {
          return (
            <ActiveFilter
              key={item}
              type={_.lowerCase(item).replace(/ /g, " ")}
              value={
                moment(filterData[item]?.value || filterData[item]).isValid()
                  ? filterData[item]?.value || filterData[item]
                  : _.lowerCase(filterData[item]?.value).replace(/ /g, " ")
              }
              onRemove={() => onRemoveFilter(item)}
            />
          );
        }
        return null;
      });
    }
  };
  const handleDateFilter = (item) => {
    setFilterData({
      ...filterData,
      start_date: moment(item.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.endDate).format("YYYY-MM-DD"),
    });
    setFilterInput({
      ...filterInput,
      start_date: moment(item.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.endDate).format("YYYY-MM-DD"),
    });
  };
  const dateRangeIsActive = useCallback(
    (item) =>
      moment(moment(item?.startDate).format("YYYY-MM-DD")).isSame(start_date) &&
      moment(moment(item?.endDate).format("YYYY-MM-DD")).isSame(end_date),
    [filterData]
  );

  return (
    <>
      {containsActiveFilter().length > 0 && (
        <div className="active-filters-container flex items-start w-full md:absolute left-0 md:top-[-83px] lg:top-[-70px]">
          <p className="title-text mr-[8px] text-blue pt-2">Filters:</p>
          <div className="active-filter-list flex items-center gap-[8px] flex-wrap ">
            {renderFilters()}
          </div>
        </div>
      )}

      <div className="bg-white flex flex-col justify-start items-start sm:items-center w-full h-fit rounded-lg border-1/2 border-grey-bordercolor pb-6 sm:pb-0 px-4 sm:px-0">
        <div className="flex justify-between items-center w-full sm:px-4 px-0 pt-5 pb-6 sm:pb-12">
          <div className="flex justify-start items-center space-x-2 w-full">
            {filterRange?.map((item) => {
              return (
                <div
                  key={item?.label}
                  className={`
                  rounded-lg bg-grey-whitesmoke px-3 py-1 text-xs cursor-pointer border hover:border hover:border-blue hover:text-blue transition-all ease-in-out duration-300 whitespace-nowrap
                  text-${
                    dateRangeIsActive(item)
                      ? "blue border border-blue"
                      : "black border-transparent"
                  }
                  `}
                  onClick={() => handleDateFilter(item)}
                >
                  {item?.label}
                </div>
              );
            })}
          </div>
        </div>

        <button
          href="#"
          className="text-grey-text font-thin flex text-sm justify-center items-center mb-2 cursor-default"
          type="button"
        >
          {balanceType?.toUpperCase()} Value{" "}
        </button>
        <h3 className="text-3xl gap-x-1 flex items-center">
          {numberWithCommas(revenue)}
          {loading && <Loader />}
        </h3>

        <div className="w-full mt-16 px-6 inflow-chart hidden sm:block">
          {loading ? (
            <div className="flex flex-grow justify-center items-center h-full w-full min-h-76">
              <CircleLoader blue />
            </div>
          ) : (
            <Chart
              options={barOptions}
              series={barSeries}
              type="bar"
              width={"100%"}
              height={300}
            />
          )}
        </div>

        <FilterModal
          active={modalVisible}
          onClose={() => setModalVisible(false)}
          setFilterData={setFilterInput}
          filterData={filterInput}
          handleFilter={handleFilter}
          clearFilters={clearFilters}
          modalType={MECHANT_TRANSACTIONS_FILTER}
        />
      </div>
    </>
  );
};

InflowOverview.propTypes = {
  tribe_account_id: PropTypes.string,
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
};

export default InflowOverview;
