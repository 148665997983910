import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import errorReducer from "./error/slice/errorSlice";
import globalSlice from "./globals";
import messageReducer from "./message/slice/messageSlice";
import miscSlice from "../redux/misc";
import signInSlice from "pages/OnBoarding/SignIn/slice";
import marchantSlice from "pages/Dashboard/Marchants/slice";
import shopperSlice from "pages/Dashboard/Shoppers/slice";
import transactionsSlice from "pages/Dashboard/Transactions/slice";
import authSlice from "./auth/slice/authSlice";
import complianceSlice from "pages/Dashboard/Compliance/slice";
import walletSlice from "pages/Dashboard/Wallet/slice";

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  globals: globalSlice.reducer,
  signIn: signInSlice.reducer,
  misc: miscSlice.reducer,
  auth: authSlice.reducer,
  error: errorReducer,
  message: messageReducer,
  transactions: transactionsSlice.reducer,
  marchants: marchantSlice.reducer,
  shoppers: shopperSlice.reducer,
  compliance: complianceSlice.reducer,
  wallet: walletSlice.reducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "auth/logout") {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    // const { users, comment } = state;
    // state = { users, comment };

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
