import moment from "moment";
import { PaymentMethod, TransactionType } from "styles/transactions";
import { numberWithCommas } from "utils/formatter";
import {
  pendingTransactionStatus,
  sucessfulTransactionStatus,
  partPaidTransactionStatus,
  failedTransactionStatus,
} from "./appConstant";

export const determineTransactionType = (type, stringValue, showSender) => {
  if (type?.tx_verb) {
    if (type?.tx_verb?.includes("credit")) {
      return stringValue ? (
        "Credit"
      ) : (
        <div className="flex flex-col gap-2 py-4">
          <span className="sm:hidden whitespace-nowrap">
            {determineSourceAccount(type) || determineTxnFrom(type) || (
              <PaymentMethod>
                {type?.tx_wallet_details?.tx_type.replace("_", " ")}
              </PaymentMethod>
            )}
          </span>
          <TransactionType className="text-[12px] sm:text-sm">
            <span className="type-badge credit"></span>
            <span className="text-grey-text sm:text-black whitespace-nowrap">
              {showSender ? `${type?.transaction_note || "Credit"}` : "Credit"}
            </span>
          </TransactionType>
        </div>
      );
    } else if (type?.tx_verb?.includes("debit")) {
      return stringValue ? (
        "Debit"
      ) : (
        <div className="flex flex-col gap-2">
          <span className="sm:hidden whitespace-nowrap">
            {determineSourceAccount(type) || determineTxnFrom(type) || (
              <PaymentMethod>
                {type?.tx_wallet_details?.tx_type.replace("_", " ")}
              </PaymentMethod>
            )}
          </span>
          <TransactionType className="text-[12px] sm:text-sm">
            <span className="type-badge debit"></span>{" "}
            <span className="text-grey-text sm:text-black whitespace-nowrap">
              {showSender ? `${type?.transaction_note || "Debit"}` : "Debit"}
            </span>
          </TransactionType>
        </div>
      );
    }

    return "";
  }

  return "";
};

export const transactionAmount = (row, isValue) => {
  if (row) {
    const {
      tx_wallet_details: { tx_type, currency },
      exchange_details,
      crypto_payin_details,
      crypto_payout_details,
      fiat_amount,
      coin_amount,
      tx_wallet_details,
      modified_date,
    } = row;
    if (tx_type.includes("fiat")) {
      return isValue ? (
        `${currency} ${numberWithCommas(fiat_amount)}`
      ) : (
        <div className="w-full flex flex-col gap-2">
          <span className="flex">
            {`${currency} ${numberWithCommas(fiat_amount)}`}
          </span>

          <span className="text-grey-text sm:hidden text-[12px] capitalize">
            {moment(modified_date).format("DD MMM YYYY; LT")}
          </span>
        </div>
      );
    } else if (tx_type.includes("crypto")) {
      return isValue ? (
        parseFloat(coin_amount) +
          " " +
          (exchange_details?.coin_type ||
            crypto_payin_details?.coin_type ||
            crypto_payout_details?.coin_type ||
            tx_wallet_details?.currency ||
            "")
      ) : (
        <div className="w-full flex flex-col gap-2">
          {parseFloat(coin_amount) +
            " " +
            (exchange_details?.coin_type ||
              crypto_payin_details?.coin_type ||
              crypto_payout_details?.coin_type ||
              tx_wallet_details?.currency ||
              "")}
          <span className="text-grey-text sm:hidden text-[12px] capitalize">
            {moment(modified_date).format("DD MMM YYYY; LT")}
          </span>
        </div>
      );
    }

    return "";
  }
};

export const transactionAmountValue = (row) => {
  if (row) {
    const {
      tx_wallet_details: { tx_type },

      fiat_amount,
      coin_amount,
    } = row;
    if (tx_type.includes("fiat")) {
      return parseFloat(fiat_amount);
    } else if (tx_type.includes("crypto")) {
      return parseFloat(coin_amount);
    }

    return "";
  }
};

export const paymentMethods = [
  {
    name: "Fiat",
    label: "Fiat",
    value: "fiat",
  },
  {
    name: "Crypto",
    label: "Crypto",
    value: "crypto",
  },
  {
    name: "Fiat and Crypto",
    label: "Fiat and Crypto",
    value: "fiat_and_crypto",
  },
  {
    name: "All Fiat",
    label: "All Fiat",
    value: "all_fiat",
  },
  {
    name: "All Crypto",
    label: "All Crypto",
    value: "all_crypto",
  },
];

export const transactionTypes = [
  {
    name: "Credit",
    label: "Credit",
    value: "credit",
  },
  {
    name: "Debit",
    label: "Debit",
    value: "debit",
  },

  {
    name: "Failed",
    label: "Failed",
    value: "failed",
  },
  {
    name: "Reversal",
    label: "Reversal",
    value: "reversal",
  },
  {
    name: "Payout",
    label: "Payout",
    value: "payout",
  },
  {
    name: "All",
    label: "All",
    value: "all",
  },
];

export const determineTransactionStatus = (type, isPayout) => {
  const acceptedPendingTxnStatus = isPayout
    ? pendingTransactionStatus
    : pendingTransactionStatus?.filter((stat) => stat !== "on_going");
  if (type) {
    if (acceptedPendingTxnStatus.includes(type)) {
      return "pending";
    } else if (sucessfulTransactionStatus.includes(type)) {
      return "completed";
    } else if (failedTransactionStatus.includes(type)) {
      return "failed";
    } else if (partPaidTransactionStatus.includes(type)) {
      return "part paid";
    }
    return "";
  }
  return "";
};

export const determineTransactionStatusStyle = (type) => {
  if (type) {
    if (type === "pending") {
      return "text-grey-text";
    } else if (type === "completed") {
      return "text-green-light";
    } else if (type === "failed") {
      return "text-red";
    } else if (type === "part paid") {
      return "text-yellow";
    }
  } else {
    return "text-black ";
  }
  return "";
};

export const determineSourceAccount = (transactionDetails) => {
  const accName = transactionDetails?.payout_details?.receiver_first_name
    ? transactionDetails?.payout_details?.receiver_first_name +
      " " +
      transactionDetails?.payout_details?.receiver_last_name
    : transactionDetails?.payout_details?.receiver_account_name
    ? transactionDetails?.payout_details?.receiver_account_name
    : transactionDetails?.payin_details?.holder_first_name
    ? transactionDetails?.payin_details?.holder_first_name +
      " " +
      transactionDetails?.payin_details?.holder_last_name
    : transactionDetails?.payin_details?.holder_account_name
    ? transactionDetails?.payin_details?.holder_account_name
    : transactionDetails?.crypto_payin_details?.coin_address
    ? transactionDetails?.crypto_payin_details?.coin_address
    : transactionDetails?.crypto_payout_details?.coin_address
    ? transactionDetails?.crypto_payout_details?.coin_address
    : "";
  return accName;
};

export const determineTxnFrom = (transactionDetails) => {
  const txnFrom = transactionDetails?.tx_verb?.includes("manual")
    ? transactionDetails?.source_account_name
    : "";
  return txnFrom;
};

export const statusType = [
  {
    name: "Approved",
    label: "Approved",
    value: "approved",
  },
  {
    name: "Pending",
    label: "Pending",
    value: "pending",
  },
  {
    name: "Unapproved",
    label: "Unapproved",
    value: "unapproved",
  },
];
