import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/General/Button";
import { ReactComponent as Fail } from "assets/icons/Toast/fail.svg";

const ErrorScreen = ({ errorTitle, errorMessage, errorButtonProps }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full gap-3">
      <span className="mb-3">
        <Fail />
      </span>

      <span className="basier-medium text-xl">
        {errorTitle || "Oops something went wrong..."}
      </span>
      <p className="text-sm text-grey-light2 text-center leading-relaxed mb-4">
        {errorMessage}
      </p>

      <Button fullWidth text="Try again" {...errorButtonProps} />
    </div>
  );
};
ErrorScreen.propTypes = {
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
  errorButtonProps: PropTypes.object,
};

export default ErrorScreen;
