import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _, { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as Empty } from "assets/icons/empty-table.svg";
import useTableFilter from "hooks/tableFilter";
import ActiveFilter from "components/General/ActiveFilter";
import TableManipulation from "components/General/Table/TableManipulation";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import Table from "components/General/Table";
import SearchBar from "components/General/Searchbar/SearchBar";
import { pageCount } from "utils/appConstant";
import { PaymentMethod } from "styles/transactions";
import { hasValue } from "utils/validations";
import { numberWithCommas } from "utils/formatter";
import {
  determineTransactionType,
  transactionAmount,
  transactionAmountValue,
} from "utils/transactions";
import useWindowDimensions from "hooks/useWindowDimensions";
import transactionsSlice from "../slice";

import { TRANSACTION_MODAL_SCREENS } from "utils/modalScreens";
import { TransactionDetailsModal } from "./ModalScreens";
import { getCellContent } from "utils/functions";

import { HiOutlineReceiptRefund } from "react-icons/hi";

const { TRANSACTION_DETAILS, EXPORT_TRANSACTIONS, FILTER_TRANSACTIONS } =
  TRANSACTION_MODAL_SCREENS;

export const requiredFilters = {
  start_date: "2020-01-01",
  end_date: moment().format("YYYY-MM-DD"),
  tx_type: "fiat",
};
export default function TransactionsList({
  currentPage,
  setCurrentPage,
  setCurrentWalletModal,
  tribe_account_id,
}) {
  const { merchantDetails } = useSelector((state) => state.marchants);

  const requiredFilters = {
    start_date: "2022-01-01",
    end_date: moment().format("YYYY-MM-DD"),
    tx_type: "fiat",
  };

  const defaultFilters = {
    start_date: "",
    end_date: "",
    tx_type: "",
    tx_verb: "",
    fiat_wallet_id: "",
    coin_wallet_id: "",
  };

  const defaultExportFilters = {
    start_date: "",
    end_date: "",
    tx_action_type: "",
    tribe_account_id: "",
  };
  const { isMobile } = useWindowDimensions();

  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [currentModal, setCurrentModal] = useState("");

  const { actions } = transactionsSlice;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Parse the query string
  const searchParams = new URLSearchParams(location.search);
  const transaction_reference = searchParams.get("transaction_reference");

  const {
    loading,
    searching,
    searchResults,
    searchResultsCount,
    transactions,
  } = useSelector((state) => state.transactions);

  const searchQuery = searchInput?.trim();
  const isSearchMode = searchQuery?.length > 1 && searchResults;
  const { filterData, clearFilters, onRemoveFilter } = useTableFilter({
    defaultFilters,
    defaultExportFilters,
    setFilterModal: setCurrentModal,
    currentPage: isSearchMode ? currentSearchPage : currentPage,
    setCurrentPage: isSearchMode ? setCurrentSearchPage : setCurrentPage,
  });

  const currentParams = new URLSearchParams(window.location.search);
  const viewTransactionDetails = ({ transaction_reference }) => {
    setCurrentModal(TRANSACTION_DETAILS);
    currentParams.set("transaction_reference", transaction_reference);
    navigate(`${window.location.pathname}?${currentParams.toString()}`);
  };

  const closeTransactionDetails = () => {
    // Remove the 'transaction_reference' parameter
    setCurrentModal("");
    currentParams.delete("transaction_reference");
    // Update the URL without reloading the page
    navigate(`${location.pathname}?${currentParams.toString()}`);
  };

  useEffect(() => {
    if (transaction_reference) {
      viewTransactionDetails({ transaction_reference });
    }
  }, [transaction_reference]);

  const fetchTransactions = () => {
    let filters = {};
    Object.keys(filterData).forEach((item) => {
      filters = {
        tribe_account_id,
        ...filters,
        ...(hasValue(filterData[item]) &&
          item !== "tx_verb" && { [item]: filterData[item] }),
        start_date: hasValue(filterData.start_date)
          ? filterData.start_date
          : requiredFilters.start_date,
        end_date: hasValue(filterData.end_date)
          ? filterData.end_date
          : requiredFilters.end_date,
        ...(hasValue(filterData.tx_verb) &&
          filterData.tx_verb.value !== "all" && {
            tx_verb: filterData.tx_verb.value,
          }),
      };
    });

    console.log("filters", filters);

    dispatch(
      actions.getTransactionsList({
        payload: filters,
        tribeRef: merchantDetails.account_tribe_ref,
        page: currentPage,
      })
    );
  };

  useEffect(() => {
    if (searchQuery) {
      setCurrentSearchPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    let timeoutId;

    const delayedFetch = () => {
      timeoutId = setTimeout(() => {
        fetchTransactions();
      }, 50); // Adjust the delay time as needed
    };

    // Clear the previous timeout every time dependencies change
    clearTimeout(timeoutId);

    if (merchantDetails?.account_tribe_ref) {
      delayedFetch();
    }

    // Cleanup function to clear timeout if component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [merchantDetails?.account_tribe_ref, currentPage, filterData]);

  useEffect(() => {
    if (!searchQuery?.length) {
      dispatch(actions.resetSearchState());
    }
  }, [searchInput, currentSearchPage]);

  const handleTransactSearch = () => {
    if (searchQuery?.length > 1) {
      const data = {
        tx_keyword: searchInput,
        tribe_account_id,
      };
      dispatch(actions.searchTransactions({ data, page: currentSearchPage }));
    } else {
      dispatch(actions.resetSearchState());
    }
  };

  const columns = [
    ...(isMobile
      ? [
          {
            name: "Description",
            minWidth: "35%",
            maxWidth: isMobile ? "45%" : "",
            selector: (row) => (
              <div onClick={() => viewTransactionDetails(row)}>
                {getCellContent(determineTransactionType(row, false, true))}
              </div>
            ),
          },
          {
            name: "Amount",
            minWidth: "20%",
            maxWidth: isMobile ? "40%" : "",
            selector: (row) => transactionAmountValue(row),
            cell: (row) => (
              <div onClick={() => viewTransactionDetails(row)}>
                {getCellContent(transactionAmount(row))}
              </div>
            ),
            sortable: true,
          },
        ]
      : [
          {
            name: "Amount",
            minWidth: "20%",
            maxWidth: isMobile ? "40%" : "",
            selector: (row) => transactionAmountValue(row),
            cell: (row) => (
              <div onClick={() => viewTransactionDetails(row)}>
                {getCellContent(transactionAmount(row))}
              </div>
            ),
            sortable: true,
          },
          {
            name: "Description",
            minWidth: "35%",
            // maxWidth: isMobile ? "40%" : "",
            selector: (row) => (
              <div onClick={() => viewTransactionDetails(row)}>
                {getCellContent(determineTransactionType(row, false, true))}
              </div>
            ),
          },
        ]),

    {
      name: "Payment Method",
      selector: (row) => (
        <PaymentMethod onClick={() => viewTransactionDetails(row)}>
          {row?.tx_wallet_details?.tx_type.replace("_", " ")}
        </PaymentMethod>
      ),
    },

    {
      name: "Date",
      minWidth: "23%",
      selector: (row) => new Date(row.pub_date),
      cell: (row) => (
        <div onClick={() => viewTransactionDetails(row)}>
          {getCellContent(moment(row.pub_date).format("DD MMM h:mmA"))}
        </div>
      ),
      sortable: true,
    },

    ...[
      filterData?.tx_verb?.value === "reversal"
        ? {
            name: "",
            cell: (row) => (
              <div
                onClick={() => {
                  viewTransactionDetails(row);
                  // handleAddParam("payout_txn_ref", row?.transaction_reference);
                }}
                className="text-blue text-sm font-medium px-3 h-8 text-center cursor-pointer bg-violet-100 rounded-md border border-blue justify-center items-center gap-2 flex whitespace-nowrap"
              >
                Retry
                <HiOutlineReceiptRefund size={18} />
              </div>
            ),
            sortable: true,
          }
        : null,
    ].filter(Boolean),
  ];

  const containsActiveFilter = () =>
    Object.keys(filterData).filter(
      (item) => filterData[item] && filterData[item] !== ""
    );

  const renderFilters = () => {
    if (filterData) {
      return containsActiveFilter().map((item) => {
        const hasChanged = defaultFilters[item] !== filterData[item];

        if (hasChanged) {
          return (
            <ActiveFilter
              key={item}
              type={_.lowerCase(item).replace(/ /g, " ")}
              value={
                moment(filterData[item]?.value || filterData[item]).isValid()
                  ? filterData[item]?.value || filterData[item]
                  : _.lowerCase(filterData[item]?.value).replace(/ /g, " ")
              }
              onRemove={() => onRemoveFilter(item)}
            />
          );
        }
        return null;
      });
    }
  };
  const renderTableHeader = () => (
    <div className="flex flex-col justify-start items-start gap-6 py-5 md:py-6 px-4 md:px-6 border-b-[0.5px] border-grey-bordercolor md:border-none">
      <div className="flex justify-between md:justify-start items-center w-full gap-x-8 overflow-x-auto no-scroll">
        <span className="text-lg basier-medium whitespace-nowrap">
          Transactions -{" "}
          {numberWithCommas(
            isSearchMode ? searchResultsCount : transactions?.count
          )}
        </span>

        <TableManipulation
          manualSearch
          placeholder="Search by account number, account name, reference, customer reference"
          onSearchChange={setSearchInput}
          searchLoading={searching}
          onSearchClick={handleTransactSearch}
          searchValue={searchInput}
          className="hidden md:flex"
          searchClass="md:max-w-sm ml-0"
        />
      </div>

      {containsActiveFilter().length > 0 && (
        <div className="active-filters-container flex items-center w-full">
          <p className="title-text mr-[8px] text-blue">Filters:</p>
          <div className="active-filter-list flex items-center space-x-[8px] overflow-x-auto">
            {renderFilters()}
          </div>
        </div>
      )}
    </div>
  );

  const displayTable =
    transactions?.data?.length > 0 || containsActiveFilter().length > 0;

  return (
    <>
      <div className="flex flex-col justify-start items-center h-full w-full gap-y-7">
        {loading ? (
          <CircleLoader blue />
        ) : (
          <>
            <div className="flex flex-col flex-grow justify-start items-center w-full h-full gap-y-3">
              {displayTable ? (
                <>
                  <div className="h-[44px] mt-2 w-full md:hidden">
                    <SearchBar
                      placeholder="Search by account number, account name, reference, customer reference"
                      onChange={setSearchInput}
                      value={searchInput}
                      className="flex"
                      manualSearch
                      onClick={handleTransactSearch}
                      isLoading={searching}
                    />
                  </div>
                  <div className="flex flex-col justify-between items-center w-full gap-3 md:hidden mb-4">
                    <TableManipulation
                      onFilter={() => setCurrentModal(FILTER_TRANSACTIONS)}
                      onExport={
                        !isEmpty(transactions?.data) && !isMobile
                          ? () => setCurrentWalletModal(EXPORT_TRANSACTIONS)
                          : null
                      }
                      className="md:hidden h-fit w-full"
                      filtersClassName="flex flex-col h-fit w-full gap-3"
                      filterButtonProps={{ fullWidth: true }}
                      exportButtonProps={{ fullWidth: true }}
                    />
                  </div>
                  <Table
                    data={isSearchMode ? searchResults : transactions?.data}
                    columns={isMobile ? columns.slice(0, 2) : columns}
                    onRowClicked={viewTransactionDetails}
                    pointerOnHover
                    isLoading={searching}
                    headerComponent={renderTableHeader()}
                    pageCount={
                      isSearchMode
                        ? searchResultsCount / pageCount
                        : transactions?.count / pageCount
                    }
                    onPageChange={(page) =>
                      isSearchMode
                        ? setCurrentSearchPage(page)
                        : setCurrentPage(page)
                    }
                    currentPage={isSearchMode ? currentSearchPage : currentPage}
                    noDataComponent={
                      <div className="text-grey-text flex flex-col justify-center items-center h-full mt-10 mb-16">
                        <Empty />
                        <span className="text-center mt-4">
                          You currently don&apos;t have any transactions
                        </span>
                      </div>
                    }
                    noPadding
                  />
                </>
              ) : (
                <>
                  <div className="text-grey-text flex flex-col justify-center items-center h-full">
                    <Empty />
                    <span>You currently don&apos;t have any transactions</span>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {currentModal === TRANSACTION_DETAILS ? (
        <TransactionDetailsModal
          active={currentModal === TRANSACTION_DETAILS}
          toggler={closeTransactionDetails}
          details={{
            modalType: TRANSACTION_DETAILS,
            clearFilters: () => {
              clearFilters();
            },
            getTransaction: () => {
              fetchTransactions();
            },
          }}
        />
      ) : null}
    </>
  );
}
TransactionsList.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  currentWalletModal: PropTypes.string,
  tribe_account_id: PropTypes.string,
  setCurrentWalletModal: PropTypes.func,
};
