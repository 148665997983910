import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import successBackground from "assets/images/success-banner.png";
import { ReactComponent as SuccessIcon } from "assets/icons/success-icon.svg";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-icon-blue.svg";
import { ReactComponent as Cancel } from "assets/icons/cancel-icon-white.svg";
import { ReactComponent as Refresh } from "assets/icons/VirtualAccounts/refresh.svg";
import { ReactComponent as ArrowBack } from "assets/icons/Arrow/arrow-right-blue-stick.svg";
import { Button } from "components/General/Button";

const ModalHeader = ({
  toggler,
  className,
  header,
  subHeader,
  isSideModal,
  isSuccess,
  useSuccessBanner,
  noPadding,
  onBackClick,
  screenHistory,
  backButtonProps,
  hideToggler,
  showActionButtonOnHeader,
  actionButtonProps,
  onRefresh,
}) => {
  const screens = screenHistory.filter((screen) => screen);

  return (
    <div
      className={classNames(
        "flex flex-col justify-start items-start w-full",
        className,
        {
          "border-none mb-0": isSideModal || (!header && !subHeader),
          "border-b-1/2 border-grey-border pb-4": !isSuccess && !isSideModal,
        }
      )}
    >
      <div
        className={classNames("flex flex-col justify-start items-start w-full")}
      >
        {isSuccess ? (
          useSuccessBanner ? (
            <div
              style={{ "--image-url": `url(${successBackground})` }}
              className={classNames(
                "bg-[image:var(--image-url)] w-full h-[155px] flex justify-end items-start px-4 md:px-[20px] py-[10px] mb-8 transform transition-transform duration-300 ease-in-out bg-no-repeat bg-cover bg-center -mt-px animate-scale-up",
                {
                  hidden: !isSuccess,
                }
              )}
            >
              <div
                className={classNames(
                  "cursor-pointer w-[35px] h-[35px] flex justify-center items-center rounded-full transition-all duration-300 ease-in-out hover:bg-grey-lighter"
                )}
                onClick={toggler}
              >
                <Cancel className="scale-[0.7]" />
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-2 w-full p-5">
              <div
                className={classNames(
                  "cursor-pointer w-[35px] h-[35px] flex justify-center items-center text-white hover:bg-grey-lighter rounded-full transition-all duration-300 ease-in-out ",
                  {
                    "ml-auto": !isSideModal,
                  }
                )}
                onClick={toggler}
              >
                <CancelIcon className="scale-[0.7]" />
              </div>

              <span className="my-1 animate-scale-up">
                <SuccessIcon />
              </span>
            </div>
          )
        ) : null}
        <div
          className={classNames(
            "w-full flex flex-col justify-start items-start",
            {
              "px-4 md:px-[20px]": !noPadding && !isSideModal,
              "pb-[20px]": isSideModal,
            }
          )}
        >
          <div
            className={classNames("flex justify-between items-start w-full", {
              hidden: isSuccess,
              "mb-5": isSideModal,
            })}
          >
            {[...new Set(screens)].length > 1 && onBackClick ? (
              <div
                className={classNames(
                  "cursor-pointer w-[35px] h-[35px] flex justify-center items-center text-white hover:bg-grey-lighter rounded-full transition-all duration-300 ease-in-out ",
                  {
                    "mr-auto": !isSideModal,
                  }
                )}
                onClick={() => {
                  if (
                    backButtonProps?.isDisabled ||
                    backButtonProps?.isLoading
                  ) {
                    return;
                  }
                  onBackClick();
                }}
              >
                <ArrowBack className="scale-90" />
              </div>
            ) : null}

            {hideToggler ? null : (
              <div
                className={classNames(
                  "cursor-pointer w-[35px] h-[35px] flex justify-center items-center text-white hover:bg-grey-lighter rounded-full transition-all duration-300 ease-in-out ",
                  {
                    "ml-auto": !isSideModal,
                  }
                )}
                onClick={toggler}
              >
                <CancelIcon className="scale-[0.7]" />
              </div>
            )}
          </div>
          {header || subHeader || showActionButtonOnHeader ? (
            <div className="w-full flex justify-between items-start gap-4">
              {header || subHeader ? (
                <div className="w-full flex flex-col justify-start items-start gap-0.5">
                  {header ? (
                    <div className="w-full flex justify-between items-center">
                      <span
                        className={"text-[16] md:text-[22px] basier-medium"}
                      >
                        {header}
                      </span>
                      {onRefresh && (
                        <Refresh
                          className="cursor-pointer"
                          onClick={() => onRefresh()}
                        />
                      )}
                    </div>
                  ) : null}

                  {subHeader ? (
                    <span className={"text-sm text-grey-light2"}>
                      {subHeader}
                    </span>
                  ) : null}
                </div>
              ) : null}

              {actionButtonProps?.text && showActionButtonOnHeader ? (
                <Button {...actionButtonProps} />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ModalHeader.propTypes = {
  toggler: PropTypes.func,
  onRefresh: PropTypes.func,
  isSideModal: PropTypes.bool,
  isSuccess: PropTypes.bool,
  useSuccessBanner: PropTypes.bool,
  className: PropTypes.string,
  header: PropTypes.any,
  subHeader: PropTypes.string,
  noPadding: PropTypes.string,
  onBackClick: PropTypes.func,
  screenHistory: PropTypes.array,
  hideToggler: PropTypes.bool,
  showActionButtonOnHeader: PropTypes.bool,
  actionButtonProps: PropTypes.object,
  backButtonProps: PropTypes.object,
};

export default ModalHeader;
