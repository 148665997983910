import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const useModal = ({
  details,
  toggler,
  onCloseModal,
  screens,
  onGoBack,
  onGoToScreen,
}) => {
  const [activeScreen, setActiveScreen] = useState(details?.modalType);
  const [screenHistory, setScreenHistory] = useState([]);
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [errorObject, setErrorObject] = useState({
    errorTitle: "",
    errorMessage: "",
  });

  useEffect(() => {
    // Update history stack if the new screen is different from the last in the stack
    if (screenHistory[screenHistory.length - 1] !== activeScreen) {
      setScreenHistory([...screenHistory, activeScreen]);
    }
  }, [activeScreen, screenHistory]);

  useEffect(() => {
    setActiveScreen(details?.modalType);
  }, [details?.modalType]);

  const handleCloseModal = () => {
    toggler?.();
    setActiveScreen?.("");
    onCloseModal?.();
  };

  const goBack = () => {
    if (screenHistory.length > 1) {
      // Remove the current screen from the history and go to the previous one
      const newHistory = screenHistory.slice(0, -1);
      setScreenHistory(newHistory);
      setActiveScreen(newHistory[newHistory.length - 1]);
      onGoBack?.();
    }
  };

  const goToScreen = (screen) => {
    setActiveScreen(screen);
    onGoToScreen?.();
  };
  const currentScreen = screens?.find((_) => _.name === activeScreen);
  return {
    activeScreen,
    setActiveScreen,
    screenHistory,
    setScreenHistory,
    currentScreen,
    handleCloseModal,
    goBack,
    onCloseModal,
    goToScreen,
    setShowErrorScreen,
    showErrorScreen,
    setErrorObject,
    errorObject,
  };
};

useModal.propTypes = {
  details: PropTypes.object,
  toggler: PropTypes.func,
  onCloseModal: PropTypes.func,
  screens: PropTypes.array,
};

export default useModal;
