import { call, put, takeLatest } from "redux-saga/effects";

import apis from "services/merchant";

import transactionsSlice from ".";
const { actions } = transactionsSlice;

function* searchMerchants(action) {
  try {
    const response = yield call(apis.searchMerchants, action.payload);
    yield put(actions.searchMerchantsSuccess(response));
  } catch (error) {
    yield put(actions.searchMerchantsError());
  }
}

function* getMerchantDetails(action) {
  try {
    const response = yield call(apis.getMerchantDetails, action.payload);
    yield put(actions.getMerchantDetailsSuccess(response));
  } catch (error) {
    yield put(
      actions.getMerchantDetailsError(
        error?.response?.data || "There was an error"
      )
    );
  }
}

function* getMerchantTransactions(action) {
  try {
    const response = yield call(apis.getMerchantTransactions, action.payload);
    yield put(actions.getMerchantTransactionsSuccess(response));
  } catch (error) {
    yield put(
      actions.getTransactionDetailsError(
        error?.response?.data || "There was an error"
      )
    );
  }
}

function* searchMerchantTransactions(action) {
  try {
    const response = yield call(
      apis.searchMerchantTransactions,
      action.payload
    );
    yield put(actions.getMerchantTransactionsSuccess(response));
  } catch (error) {
    yield put(
      actions.getTransactionDetailsError(
        error?.response?.data || "There was an error"
      )
    );
  }
}

function* getMerchantTransactionsGraphData(action) {
  try {
    const response = yield call(
      apis.getMerchantTransactionsGraphData,
      action.payload
    );
    yield put(actions.getMerchantTransactionsGraphDataSuccess(response));
  } catch (error) {
    yield put(
      actions.getMerchantTransactionsGraphDataError(
        error?.response?.data || "There was an error"
      )
    );
  }
}
// function* getTransactionDetails(action) {
//   try {
//     const { data } = yield call(apis.getTransactionDetails, action.payload);
//     yield put(actions.getTransactionDetailsSuccess(data));
//   } catch (error) {
//     yield put(
//       actions.getTransactionDetailsError(
//         error?.response?.data || "There was an error"
//       )
//     );
//   }
// }
export function* marchantsSaga() {
  yield takeLatest(actions.searchMerchants.type, searchMerchants);
  yield takeLatest(actions.getMerchantDetails.type, getMerchantDetails);
  yield takeLatest(
    actions.getMerchantTransactions.type,
    getMerchantTransactions
  );
  yield takeLatest(
    actions.searchMerchantTransactions.type,
    searchMerchantTransactions
  );
  yield takeLatest(
    actions.getMerchantTransactionsGraphData.type,
    getMerchantTransactionsGraphData
  );
  // yield takeLatest(actions.getTransactionDetails.type, getTransactionDetails);
}
